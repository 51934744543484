import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { DataService } from "./data.service";
import { NotificationService } from "./notification.service";
import { BehaviorSubject } from 'rxjs'
import * as firebase from 'firebase/compat';
const config = {
  apiKey: "AIzaSyBq-uSJu7bJ71hKpvgkx4hMJOBYzrc5IDs",
  authDomain: "kychub-61bdf.firebaseapp.com",
  projectId: "kychub-61bdf",
  storageBucket: "kychub-61bdf.appspot.com",
  messagingSenderId: "1098502164556",
  appId: "1:1098502164556:web:fa20304e1ce88463c6e4e9",
  measurementId: "G-Q8V7LBZLLX"
};
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
   messaging =getMessaging();
  constructor(
    private _data: DataService,
    private _noti: NotificationService,
    ) {}
  requestPermission(){
    getToken(this.messaging, { vapidKey: 'BHzMWOuBAbt99VMJ7uoVKxkc_padyqpZvhfJnZCF_mJ6I1qdKbbpp7PpUkzfX8ZnWU7ROwWXn7j8EtfIg_ugJqo' }).then((currentToken) => {
      if (currentToken) {
        console.log("fb token received:",currentToken);
          let firebaseToken = this._data.getCookiesByName('fb_token');
          if(!firebaseToken || firebaseToken!=currentToken){
              this.updateToken(currentToken);
          }
         this.receiveMessage();
      } else {
          console.log('Notification permission granted.');
      }
    }).catch((err) => {
      console.log('Unable to get permission to notify.', err);
    });
  }
  updateToken(firebaseToken) {
    let data={'token':firebaseToken}
        this._noti.registerDeviceId(data).subscribe((response)=>{
            this._data.setCookieByName('fb_token', firebaseToken);
        }, error=>{
        });
    }
  receiveMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("getting here",payload)
      this.currentMessage.next(payload)
    });
  }
}