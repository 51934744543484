import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rule-query-preview',
  templateUrl: './rule-query-preview.component.html',
  styleUrls: ['./rule-query-preview.component.scss']
})
export class RuleQueryPreviewComponent implements OnChanges, OnInit {
  @Input() rulePreviewArray: any;
  operatorsSymbol = {
    GTE: '>=',
    LTE: '<=',
    EQ: '=',
    LT: '<',
    GT: '>'
  }

  constructor() { }
  ngOnInit(): void {
    console.log('called2', this.rulePreviewArray)
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('called', changes['rulePreviewArray'])

    if (changes['rulePreviewArray']?.currentValue) {
      this.rulePreviewArray = changes['rulePreviewArray']['currentValue'];
    }
    else {
      this.rulePreviewArray = this.rulePreviewArray;
    }
    this.getPreviewData()
  }

  getPreviewData() {
    this.rulePreviewArray?.groups.forEach((f) => {
      f.scenarios.forEach((item) => {
        let finalString = '( )';
        console.log('item', item)
        finalString = `( ${item.attributeName ? item.attributeName : ''} ${item.operator || ''} ${item.userInput ? item.userInput : ''} )`


        item['previewString'] = finalString;
      });
    })
  }

  getOperatorSymbol(operator) {
    if (operator) {
      return this.operatorsSymbol[operator];
    }
    return '';
  }

  getDataSourceSplit(datasource) {
    if (datasource?.length) {
      let joinedString;
      if (datasource?.length == 1) {
        joinedString = datasource[0];
      }
      else if (datasource?.length == 2) {
        joinedString = datasource.join(" and ");
      }
      else {
        const lastValue = datasource.pop();
        joinedString = datasource.join(", ") + ", and " + lastValue;
      }
      return joinedString;
    }
    return '';
  }

}
