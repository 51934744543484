import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { CommonService } from '../../services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {
  @Input() item;
  @Input() isHyperLink?: boolean = false;
  @Output() close = new EventEmitter<any>();
  transactionDetails: any;
  status: string;
  amlScreenins: any[] = [];
  amlUseArray: any[] = [];
  theme:any = 'theme-light';
  colors=[{key:'sip',color:'#ED7C15'},{key:'pep',color:'#8B60D4'},{key:'sie',color:'#ED7C15'},{key:'crime',color:'#E05B3B'},{key:'watchlist',color:'#D262BA'},
  {key:'sanction',color:'#DCAF0E'}];
  crrData: any[] = [];
  adverseScreening: any[] = [];
  ameUseArray: any[] = [];
  name:any;
  isReport:any=null;
  total: number = 0;
  sum = 10;
  directionLinks: boolean = true;
  maxSize: number = 9;
  page: number = 1;
  attributesList: any[] = [
    { name: 'Alert ID', slug: 'alert-id', value: true, sort: true, sortAttr : 'guid' },
    { name: 'Rule Name', slug: 'rule-name', value: true, sort : false },
    { name: 'Customer ID', slug: 'customer-id', value: false, sort : false},
    { name: 'Customer Name', slug: 'customer-name', value: false, sort : false },
    { name: 'Transaction ID', slug: 'transaction-id', value: false, sort : true, sortAttr : 'transactionId' },
    { name: 'Created Date', slug: 'created-date', value: true, date: true, sort : true, sortAttr : 'createdDate' },
    { name: 'Last modified on', slug: 'last-modified-on', value: false, date: true, sort : true, sortAttr : 'lastModifiedDate' },
    { name: 'SLA', slug: 'sla', value: true, sort : true,  sortAttr : 'sla', numeric: true },
    { name: 'Time Left', slug: 'time-left', value: true, sort : false, numeric: true },
    { name: 'Status', slug: 'status', value: true, sort : true, sortAttr : 'userAlertStatus' },
    { name: 'Assigned To', slug: 'assigned-to', value: false, sort : false },
    { name: 'Severity', slug: 'severity', value: false, sort : true, sortAttr : 'alertCategory' },
  ];
  order: string = 'DESC';
  orderBy: string = 'createdDate';
  allAlerts: any[] = [];
  isCardView: boolean = false;
  bankUseArray: any[] = [];
  customerBankDetails: any[] = [];
  // customerAccountAttributes: any[] = [
  //   { name: 'Bank Name', slug: 'bankName' },
  //   { name: 'Bank Branch Name', slug: 'bankBranchName' },
  //   { name: 'Account Number', slug: 'accountNumber' },
  //   { name: 'Account Sharing Type', slug: 'accountSharingType' },
  //   { name: 'Account Status', slug: 'accountStatus' },
  //   { name: 'Account Type', slug: 'accountType' },
  //   { name: 'Bank Branch Address', slug: 'bankBranchAddress' },
  //   { name: 'Bank Headquarter Country', slug: 'bankHeadquarterCountry', noObj: true }
  // ];
  sticky: boolean = false;
  tableHeaderCount: number = 0;
  tabs: any[] = [
    {
      heading: 'Summary',
      slug: 'summary'
    },
    {
      heading: 'Customer Details',
      slug: 'customer-details'
    },
    {
      heading: 'Risk Screening',
      slug: 'risk-screening'
    },
    {
      heading: 'Rules',
      slug: 'rules'
    },
    {
      heading: 'Alerts',
      slug: 'alerts'
    }
  ];
  activeTabs: string = 'summary';
  remitterScreening = {
    crrScore: 0,
    crrLable: 'Low',
    categoriesList: [],
    entityType: ''
  };
  beneficiaryScreening = {
    crrScore: 0,
    crrLable: 'Low',
    categoriesList: [],
    entityType: ''
  };
  public canvasWidth = 200
  public needleValue = 0
  public crrScore=0;
  public crrLabel='0'
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#17B18C', '#ED7D15', '#D84040'],
    arcDelimiters: [30,50],
    rangeLabel: ['0', '100'],
    needleStartValue: 0,
  };
  ruleList = {
    totalPage: 0,
    page: 1,
    size: 10,
    type: 'true',
    list: [],
    total: 0
  };
  ruleVoilatedList = {
    totalPage: 0,
    page: 1,
    size: 10,
    type: 'false',
    list: [],
    total: 0
  };
  rulePreviewArray: { outerBraces: boolean; groups: any[]; };

  constructor(private commonService: CommonService, private data: DataService, private modal: NgbModal,) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= 6){
          this.sticky = true;
      } else {
          this.sticky = false;
      }
  }

  ngOnInit(): void {
    // Hide Sidebar
    var element = document.getElementById("wrapper");
    let sidebarLogo = document.getElementById('sidebar-logo');
    let hideLogo = document.getElementById('hide-logo');
    element.classList.add("forced", "enlarged");
    if(sidebarLogo) sidebarLogo.style.marginLeft = '-12px';
    if(hideLogo){
      hideLogo.style.display = 'none';
      this.data.sideBarHiddenVal(true);
    }
    // Hide Sidebar

    // Calculate total count
    this.tableHeaderCount = this.attributesList.reduce((counter, obj) => {
      if (obj?.value) counter += 1
      return counter;
    }, 0);
    this.data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    });
    this.commonService.getRiskDetails(this.item.remitterIdNumber).subscribe((res:any)=>{
      this.status=res?.data || '';
    })
    if (this.isHyperLink == true) {
      if (this.item.alert) {
        this.getTransactionDetails(this.item.alert.transactionGuid);
      }
    }
    else {
      if (this.item) {
        this.getTransactionDetails(this.item.guid);
      }
    }
    this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
    this.getRuleListByTransactionId(this.ruleList, this.item?.transactionId);
    this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
  }

  async getTransactionDetails(guid: string) {
    this.data.changeLoaderVisibility(true);
    const res = await this.commonService.getTransactionDetailsByGuid(guid).toPromise();
    if (res && res.content.length > 0) {
      this.transactionDetails = res['content'][0];
      // For Adverse Media
      const adverseName = [];
      adverseName.push(this.transactionDetails?.remitterName);
      adverseName.push(this.transactionDetails?.beneficiaryName);
      this.adverseScreening = [
        { heading: 'Remitter', name: adverseName[0], details: [] },
        { heading: 'Beneficiary', name: adverseName[1], details: [] }
      ];
      for(var k =0; this.adverseScreening?.length > k; k++){
        this.getIndexValueForAME(k);
      }
      const adverseArticles = adverseName.map(async (name) => {
        return {
          data: await this.getAdverseMedia(name)
        }
      });
      Promise.all(adverseArticles).then((e) => {
        this.adverseScreening[0].details = e[0] && e[0]?.data;
        this.adverseScreening[1].details = e[1] && e[1]?.data;
      });

      // // For Account Details
      // const customerIds = [];
      // customerIds.push(this.transactionDetails?.remitterIdNumber);
      // customerIds.push(this.transactionDetails?.beneficiaryIdNumber);
      // this.customerBankDetails = [
      //   { heading: 'Remitter', details: [] },
      //   { heading: 'Beneficiary', details: [] }
      // ];
      // for(var k =0; this.customerBankDetails?.length > k; k++){
      //   this.getIndexValueForBankDetails(k);
      // }
      // const customerIdDetails = customerIds.map(async (id) => {
      //   return {
      //     data: await this.getAccountList(id)
      //   }
      // });
      // Promise.all(customerIdDetails).then((e) => {
      //   this.customerBankDetails[0].details = e[0] && e[0]?.data as any;
      //   this.customerBankDetails[1].details = e[1] && e[1]?.data as any;
      // });

      // For AMl Summary
      if (this.transactionDetails && this.transactionDetails?.remitterIdNumber) {
        let apiUrl;
        this.transactionDetails?.remitterAccountNumber ? apiUrl = `transacto/v1/customer?accNum=${this.transactionDetails?.remitterAccountNumber}&cust-id=${this.transactionDetails?.remitterIdNumber}` : apiUrl = `transacto/v1/customer?cust-id=${this.transactionDetails?.remitterIdNumber}`;
        const apidata = await this.commonService.getCustomerDetailByCustomerId(apiUrl).toPromise();
        var remitterAmlData = [];
        var remitterGuid: string = '';
        var remitterCRR: number = 0;
        var remitterCount: number = 0;
        var remitterObj = { name: this.transactionDetails?.remitterName, category: [], details: []  }
        if(apidata?.riskCalculation && apidata?.riskCalculation?.crrResult){
          const parseCrrData = JSON.parse(apidata?.riskCalculation?.crrResult);
          this.remitterScreening['crrScore'] = parseCrrData.crrScore.toFixed(2);
          this.remitterScreening['crrLable'] = parseCrrData.amlLabel;
        }
        if(apidata?.riskCalculation && apidata?.riskCalculation?.amlResult){
          const parseRemitterAmlData = JSON.parse(apidata?.riskCalculation?.amlResult);
          if(parseRemitterAmlData?.data?.content && parseRemitterAmlData?.data?.content?.length){
            this.remitterScreening['categoriesList'] = parseRemitterAmlData?.data?.content[0]?.masterData?.category || [];
            this.remitterScreening['entityType'] = parseRemitterAmlData?.data?.content[0]?.masterData?.entityType || '';
          }
          if(parseRemitterAmlData?.data && parseRemitterAmlData?.data?.content && parseRemitterAmlData?.data?.content?.length){
            if(parseRemitterAmlData?.data?.content[0]?.details){
              remitterGuid = parseRemitterAmlData?.data?.content[0]?.guid
              for(let details of parseRemitterAmlData?.data?.content[0]?.details){
                remitterObj?.details.push(details);
                remitterCount += 1;
                for(let cat of details?.category){
                  const index = remitterObj['category'].findIndex(e => e?.name == cat?.name)
                  index == -1 ? remitterObj['category'].push(cat) : '';
                }
              }
            }
          }
        }
        if(apidata?.riskCalculation && apidata?.riskCalculation?.crrResult){
          const remitterScore = JSON.parse(apidata?.riskCalculation?.crrResult);
          remitterCRR = remitterScore?.crrScore.toFixed(2);
        }
        remitterAmlData.push(remitterObj);
      }
      if (this.transactionDetails && this.transactionDetails?.beneficiaryIdNumber) {
        let apiUrl;
        this.transactionDetails?.beneficiaryAccountNumber ? apiUrl = `transacto/v1/customer?accNum=${this.transactionDetails?.beneficiaryAccountNumber}&cust-id=${this.transactionDetails?.beneficiaryIdNumber}` : apiUrl = `transacto/v1/customer?cust-id=${this.transactionDetails?.beneficiaryIdNumber}`;
        const data = await this.commonService.getCustomerDetailByCustomerId(apiUrl).toPromise();
        var beneficiaryAmlData = [];
        var beneficiaryGuid: string = '';
        var beneficiaryCRR: number = 0;
        var beneficiaryCount: number = 0;
        var beneficiaryObj = { name: this.transactionDetails?.beneficiaryName, category: [], details: []  }
        if(data?.riskCalculation && data?.riskCalculation?.crrResult){
          const parseCrrData = JSON.parse(data?.riskCalculation?.crrResult);
          this.beneficiaryScreening['crrScore'] = parseCrrData.crrScore.toFixed(2);
          this.beneficiaryScreening['crrLable'] = parseCrrData.amlLabel;
        }
        if(data?.riskCalculation && data?.riskCalculation?.amlResult){
          const parseBeneficiaryAmlData = JSON.parse(data?.riskCalculation?.amlResult);
          if(parseBeneficiaryAmlData?.data?.content && parseBeneficiaryAmlData?.data?.content?.length){
            this.beneficiaryScreening['categoriesList'] = parseBeneficiaryAmlData?.data?.content[0]?.masterData?.category || [];
            this.beneficiaryScreening['entityType'] = parseBeneficiaryAmlData?.data?.content[0]?.masterData?.entityType || '';
          }
          if(parseBeneficiaryAmlData?.data && parseBeneficiaryAmlData?.data?.content && parseBeneficiaryAmlData?.data?.content?.length){
            if(parseBeneficiaryAmlData?.data?.content[0]?.details){
              beneficiaryGuid = parseBeneficiaryAmlData?.data?.content[0]?.guid
              for(let details of parseBeneficiaryAmlData?.data?.content[0]?.details){
                beneficiaryObj?.details.push(details);
                beneficiaryCount += 1;
                for(let cat of details?.category){
                  const index = beneficiaryObj['category'].findIndex(e => e?.name == cat?.name)
                  index == -1 ? beneficiaryObj['category'].push(cat) : '';
                }
              }
            }
          }
        }
        if(data?.riskCalculation && data?.riskCalculation?.crrResult){
          const BeneficiaryScore = JSON.parse(data?.riskCalculation?.crrResult);
          beneficiaryCRR = BeneficiaryScore?.crrScore.toFixed(2);
        }
        beneficiaryAmlData.push(beneficiaryObj);
      }
      this.amlScreenins = [
        { heading: 'Remitter', count: remitterCount, details: remitterAmlData, crrScore: remitterCRR, guid: remitterGuid },
        { heading: 'Beneficiary', count: beneficiaryCount, details: beneficiaryAmlData, crrScore: beneficiaryCRR, guid: beneficiaryGuid },
      ];
      for(var j =0; this.amlScreenins?.length > j; j++){
        this.getIndexValueForAML(j)
      }
    }
    this.data.changeLoaderVisibility(false);
  }

  // Get Color of Tags

  getColor(category):string{
    for(let i=0;i<this.colors.length;i++){
      if(category==this.colors[i].key){
        return 'background-color:'+this.colors[i].color
      }
    }
    return 'background-color:#ED7C15'
  }
  
  // For Auto Expand

  getIndexValueForAML(i){
    if(this.amlUseArray.indexOf(i) > -1){
      this.amlUseArray.splice(this.amlUseArray.indexOf(i), 1)
    }
    else{
      this.amlUseArray.push(i)
    }    
  }

  getIndexValueForAME(i){
    if(this.ameUseArray.indexOf(i) > -1){
      this.ameUseArray.splice(this.ameUseArray.indexOf(i), 1)
    }
    else{
      this.ameUseArray.push(i)
    }    
  }

  getIndexValueForBankDetails(i){
    if(this.bankUseArray.indexOf(i) > -1){
      this.bankUseArray.splice(this.bankUseArray.indexOf(i), 1)
    }
    else{
      this.bankUseArray.push(i)
    }    
  }

  // CRR Break Points

  crrBreakPoint(guid, TemplateRef){
    if(guid){
      const payload = {
        guid: guid
      }
      this.data.changeLoaderVisibility(true);
      this.commonService.getCrrBreakPoints(payload).subscribe((res) =>{
        this.modal.open(TemplateRef, {
          ariaLabelledBy: "modal-basic-title",
          size: "md",
          backdrop: "static",
          modalDialogClass:
            this.theme === "theme-dark" ? "dark-modal" : "light-modal",
        });
        this.data.changeLoaderVisibility(false);
        if(res && res?.content && res?.content?.length){
          this.crrData = res?.content[0]?.riskProfile?.riskFactor || [];
        }
      })
    }
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  // Adverse Media Screening

  async getAdverseMedia(name: string){
    const payload = {
      entityName: name
    }
    this.data.changeLoaderVisibility(true);
    const res = await this.commonService.getAdverseArticles(payload).toPromise();
    this.data.changeLoaderVisibility(false);
    return res?.data || [];
  }

  // For Account Details

  // async getAccountList(customerId){
  //   const payload = {
  //     "order": "ASC",
  //     "orderBy": "string",
  //     "page": 0,
  //     "size": 0
  //   }
  //   this.data.changeLoaderVisibility(true);
  //   const res = await this.commonService.getAccountList(customerId, payload).toPromise();
  //   this.data.changeLoaderVisibility(false);
  //   let bankData = [];
  //   if(res && res?.length){
  //     res.forEach((item) => {
  //       let account = { bankDetails: [] }
  //       for(let [key] of Object.entries(item)){
  //         this.customerAccountAttributes.forEach((e) => {
  //           if(e?.slug == key){
  //             let obj = { key: '', value: '' }
  //             obj['key'] = e?.name;
  //             if(e?.noObj){
  //               obj['value'] = item[key]?.name;
  //             }
  //             else{
  //               obj['value'] = item[key];
  //             }
  //             account?.bankDetails.push(obj);
  //           }
  //         });
  //       }
  //       bankData.push(account);
  //     })
  //   }
  //   return bankData;
  // }

  selectedTabs(slug: string){
    this.goToTabs(slug);
    this.activeTabs = slug;
  }

  goToTabs(div){
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(div)
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    if(this.activeTabs!=div){
      let scrolly=topOfElement-250;
      window.scroll({ top: scrolly, behavior: "smooth" });
    }
    else{
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  // Alerts Section

  toggleView(){
    if(!this.isCardView){
      this.isCardView = true;
    }
    else{
      this.isCardView = false;
    }
  }

  getAlertsList(order, orderBy, transactionId: string){
    const payload = {
      "order": order,
      "orderBy": orderBy,
      "page": this.page - 1,
      "size": this.sum,
      "transactionId": transactionId
    }
    this.data.changeLoaderVisibility(true);
    this.commonService.getRuleBreachListByTransactionId(payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res) => {
      this.allAlerts= res?.content || [];
      this.total = res['totalElements'];
      this.updateTableValue();
    })
  }

  // Tbale header limit upto 7

  tableHeaderLimit(item){
    if(item?.value){
      this.tableHeaderCount += 1;
    }
    else{
      this.tableHeaderCount -= 1;
    }

    if(this.tableHeaderCount > 7){
      item.value = false;
    }
    else{
      this.updateTableValue();
    }
  }

  showLimitPopup(){
    if(this.tableHeaderCount >= 7) this.data.toastrShow('You can select upto 7 attributes', 'info');
  }

  updateTableValue(){
    if(this.allAlerts?.length){
      this.allAlerts.forEach((e) =>{
        const rowData = [...this.attributesList.map((data) => {
          if(data.slug == 'alert-id'){
            data['rowData'] = e?.alert && e?.alert?.alertId;
          }
          else if(data.slug == 'customer-id'){
            data['rowData'] = e?.alert && e?.alert?.remitterId;
          }
          else if(data.slug == 'customer-name'){
            data['rowData'] = e?.alert && e?.alert?.transaction && e?.alert?.transaction?.remitterName;
          }
          else if(data.slug == 'rule-name'){
            data['rowData'] = e?.transactionRule && e?.transactionRule?.name;
          }
          else if(data.slug == 'transaction-id'){
            data['rowData'] = e?.alert && e?.alert?.transactionId;
          }
          else if(data.slug == 'created-date'){
            data['rowData'] = e?.alert && e?.alert?.createdDate;
          }
          else if(data.slug == 'last-modified-on'){
            data['rowData'] = e?.lastModifiedDate;
          }
          else if(data.slug == 'sla'){
            data['rowData'] = e?.alert && e?.alert?.sla;
          }
          else if(data.slug == 'status'){
            data['rowData'] = e?.alert && e?.alert?.alertStatus;
          }
          else if(data.slug == 'resolution'){
            data['rowData'] = e?.alert && e?.alert?.remitterId;
          }
          else if(data.slug == 'time-left'){
              if(e?.createdDate){
                const remaining = e?.createdDate + (e?.alert?.sla * 60000)
                const alertDate = new DatePipe('en-US').transform(new Date(remaining), 'EEEE, MMMM d, y, h:mm:ss a zzzz');
                const systemDate = new Date();
                var momentAlertDate = moment(alertDate);
                var momentSystemDate = moment(systemDate)
                const duration = moment.duration(momentAlertDate.diff(momentSystemDate));
                data['rowData'] = duration.asMinutes().toFixed(2);
              }
          }
          else if(data.slug == 'assigned-to'){
            if(e?.clientsUser){
              const firstName = e?.clientsUser?.firstName || '';
              const middleName = e?.clientsUser?.middleName || '';
              const lastName = e?.clientsUser?.lastName || '';
              const clientsUserName = `${firstName} ${middleName} ${lastName}`
              data['rowData'] = clientsUserName.trim().replace(/ +/g, ' ');
            }
            else{
              data['rowData'] = null;
            }
            
          }
          else if(data.slug == 'severity'){
            data['rowData'] = e?.alert && e?.alert?.alertCategory;
          }
          return {
            ...data
          }
        })
      ]
        e['attributeList'] = rowData;
      })
    }
  }

  orderByFilter(item){
    this.orderBy = item?.sortAttr;
    if(!item?.sortData){
      item.sortData = true;
      this.order = 'ASC';
    }
    else{
      item.sortData = false;
      this.order = 'DESC';
    }
    this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
  }

  pageChange(page){
    this.page=(page);
    this.getAlertsList(this.order, this.orderBy, this.item?.transactionId);
  }

  removeDefaultOr(ruleArray){
    // Manipulate the data as per requirements
    const fetchGroup = [...ruleArray.map((group) => {
      const scenarios = group.scenarios.map((scs) => {
        if(scs?.selectedType == 'User_Input'){
          scs['inputValue'] = scs?.eq ? scs?.eq : scs?.let ? scs?.lte : scs?.gte;
          scs['operator'] = scs?.eq ? 'EQ' : scs?.let ? 'LTE' : 'GTE';
        }
        return scs
      });
      return {
        ...group, scenarios
      }
    })];

    // Remove the default OR
    for(let i = 0; fetchGroup?.length > i; i++){
      if(i < fetchGroup?.length -1){
        fetchGroup[i]['connector'] = fetchGroup[i+1]['connector'];
      }
      else{
        fetchGroup[i]['connector'] = '';
      }
      for(let j = 0; fetchGroup[i].scenarios?.length > j; j++){
        if(j < fetchGroup[i].scenarios?.length -1){
          fetchGroup[i].scenarios[j]['connector'] = fetchGroup[i].scenarios[j+1]['connector'];
        }
        else{
          fetchGroup[i].scenarios[j]['connector'] = '';
        }
      }
    }
    return fetchGroup
  }

  // Rule Query

  ruleQueryModal(ruleGroup, TemplateRef){
    if(ruleGroup?.length){
      this.modal.open(TemplateRef, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        modalDialogClass:
          this.theme === "theme-dark" ? "dark-modal" : "light-modal",
      });
      
      const fetchGroup = this.removeDefaultOr(ruleGroup);
      var ruleDataArray = [];
      ruleDataArray = fetchGroup;
      var ruleData = { outerBraces : false, groups: ruleDataArray }
      if(ruleDataArray?.length > 1) ruleData['outerBraces'] = true;
      this.rulePreviewArray = ruleData;
      }
  }
  getRuleListByTransactionId(object: Object, transactionId){
    const payload = {
      page: object['page'] - 1,
      size: object['size'],
      order: "DESC",
      orderBy: "createdDate",
      transactionId: transactionId,
    }
    this.commonService.getListOfAllRulesByTransactionId(payload, object['type']).subscribe((res) => {
      if(object['type'] == 'false'){
        this.ruleVoilatedList['list'] = res['content'] || [];
        this.ruleVoilatedList['total'] = res['totalElements'] || 0;
        this.ruleVoilatedList['totalPage'] = res['totalPages'] || 0;
      }
      else if(object['type'] == 'true'){
        this.ruleList['list'] = res['content'] || [];
        this.ruleList['total'] = res['totalElements'] || 0;
        this.ruleList['totalPage'] = res['totalPages'] || 0;
      }
    });
  }

  handleIncrease(type: string){
    if(type == 'true'){
      if(this.ruleList['page'] < this.ruleList['totalPage']){
        this.ruleList['page'] += 1;
        this.getRuleListByTransactionId(this.ruleList, this.item?.transactionId);
      }
    }
    else if(type == 'false'){
      if(this.ruleVoilatedList['page'] < this.ruleVoilatedList['totalPage']){
        this.ruleVoilatedList['page'] += 1;
        this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
      }
    }
  }

  handleDecrease(type: string){
    if(type == 'true'){
      if(this.ruleList['page'] > 1){
        this.ruleList['page'] -= 1;
        this.getRuleListByTransactionId(this.ruleList, this.item?.transactionId);
      }
    }
    else if(type == 'false'){
      if(this.ruleVoilatedList['page'] > 1){
        this.ruleVoilatedList['page'] -= 1;
        this.getRuleListByTransactionId(this.ruleVoilatedList, this.item?.transactionId);
      }
    }
  }

}
