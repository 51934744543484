import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from '../../../../app/services';
import { elementIds } from '../constants';

@Component({
  selector: 'app-policy-breakdown',
  templateUrl: './policy-breakdown.component.html',
  styleUrls: ['./policy-breakdown.component.scss','./../customer-detail.component.scss']
})
export class PolicyBreakdownComponent implements OnInit,OnChanges {
  @Input() customerRiskDetails;
  elementIds=elementIds;
  activeTabs: any='defaultView';
  widgetsData={
    type:'customer-details',
    data:[],
  }
  theme: any; 
  activeTab:string;
  leftSideData: any;
  rightSideData: any;
  alertWidget: { name: any; series: { name: any; value: any; }[]; }[];
  tableData: { name: any; list: any[]; }[];

  constructor(private dataService: DataService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.customerRiskDetails.currentValue){
      this.customerRiskDetails = changes.customerRiskDetails.currentValue;
    }
    // console.log(this.customerRiskDetails,JSON.parse(this.customerRiskDetails?.[0].amlResult || "[]"))
    if(this.customerRiskDetails){
      this.formatIntoWidgetData()
    }
  }

  ngOnInit(): void {
  }

  formatIntoWidgetData(){
    this.customerRiskDetails.forEach(eh => eh['date']=new DatePipe('en-US').transform(eh?.createdDate, 'dd-MM-YY'))
    let arr=[];
    console.log(this.customerRiskDetails)
    for(let ehObj of this.customerRiskDetails){
      for(let index of ehObj?.policyAppliedList){
        let obj = {}
        let ehVal = ehObj?.customerPolicyRiskData?.policyOutput?.[index];
        let filterData = ehVal?.output?.filter(eh => eh?.dataPoint === "Final Score")
        let value = parseInt(filterData?.[0]?.output[0]?.value)
        obj['policyName']=index;
        obj['value']=value;
        obj['date']=ehObj?.['date'];
        obj['data']=ehVal;
        arr.push(JSON.stringify(obj))
      }
    }
    let sortedData = Array.from(new Set(arr)).map(eh => JSON.parse(eh));
    let filteredPolicyNames = Array.from(new Set(sortedData.map(eh => eh.policyName)))
    let filteredDates = Array.from(new Set(sortedData.map(eh => eh.date)))
    let widgetData = filteredPolicyNames.map(eh => {
      return{
        name:eh,
        series:sortedData.map(ehs => {
          if(ehs?.policyName === eh){
            return {
              name:ehs.date,
              value:ehs.value
            }
          }
        })
      }
    })
    let tableData = filteredDates.map(eh => {
      return{
        name:eh,
        list:sortedData.filter(ehs => {
          if(ehs?.date === eh){
            return{
              listData:ehs
            }
          }
          return false
        })
      }
    })
    this.tableData = tableData
    this.alertWidget = widgetData
    this.activeTab = this.tableData[0].name;
    this.leftSideData = this.tableData[0];
    this.rightSideData = this.tableData[this.tableData.length -1];
  }

  setActiveTab(slug){
    this.activeTab = slug;
    this.leftSideData = this.tableData.filter(eh => eh.name === slug)[0]
  }

  getTheme(){
    this.dataService.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    });
  }

  selectedTab(tab) {
    this.activeTabs = tab;
  }

}
