export { AddComponent } from './add.component';
export { AlertComponent } from './alert.component';
export { CrossComponent } from './cross.component';
export { CustomerComponent } from './customer.component';
export { DataIngestionComponent } from './data-ingestion.component';
export { DownloadComponent } from './download.component';
export { DropdownArrowComponent } from './dropdownArrow.component';
export { EditComponent } from './edit.component';
export { HomeComponent } from './home.component';
export { RedirectToComponent } from './redirect-to.component';
export { ReportComponent } from './report.component';
export { ResolutionComponent } from './resolution.component';
export { RulesComponent } from './rules.component';
export { SearchFilterComponent } from './searchFilter.component';
export { SettingsComponent } from './settings.component';
export { SubComponent } from './sub.component';
export { TransactionComponent } from './transaction.component';
export { ViewReportComponent } from './view-report.component';
export { YourDataComponent } from './your-data.component';
export {KycIconComponent} from './kyc-icon.component'