import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  Resolution,
  ResolutionContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-resolution',
  template: Resolution.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResolutionComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = Resolution.height;
  @Input() public viewBox: string = getSVGViewBox(Resolution.viewBox);
  @Input() public width: number | string = Resolution.width;
  @Input()
  public set context(ctx: ResolutionContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: ResolutionContext = Resolution.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = ResolutionComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : Resolution.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
