import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  Cross,
  CrossContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-cross',
  template: Cross.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = Cross.height;
  @Input() public viewBox: string = getSVGViewBox(Cross.viewBox);
  @Input() public width: number | string = Cross.width;
  @Input()
  public set context(ctx: CrossContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: CrossContext = Cross.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = CrossComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : Cross.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
