import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  DataIngestion,
  DataIngestionContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-data-ingestion',
  template: DataIngestion.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataIngestionComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = DataIngestion.height;
  @Input() public viewBox: string = getSVGViewBox(DataIngestion.viewBox);
  @Input() public width: number | string = DataIngestion.width;
  @Input()
  public set context(ctx: DataIngestionContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: DataIngestionContext = DataIngestion.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = DataIngestionComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : DataIngestion.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
