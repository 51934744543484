export const pages = {
    ALERT: 'ALERT',
    CUSTOMER: 'CUSTOMER',
    TRANSACTION: 'TRANSACTION',
    DASHBOARD: 'HOME',
    RULE: 'RULE',
    CRA_ALERT: 'CRA_ALERT',
    YOUR_DATA : 'YOUR_DATA',
    CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
    YOUR_DATA_DETAIL: 'YOUR_DATA_DETAIL',
    CRA_CUSTOMER:'CRA_CUSTOMER',
    IMPACT_ANALYSIS_ALERT_REMEDIATION: 'IMPACT_ANALYSIS_ALERT_REMEDIATION'
}
export const charts = {
    PIE: 'pie',
    LINE: 'line',
    BAR: 'bar',
    DOUGHNUT:'doughnut',
}
export const entities = {
    ALERT: 'alerts',
    CUSTOMER: 'customer',
    TRANSACTION: 'transaction',
}
export const viewCustomiseInsightsButtons = ["HOME"]
export const insightPannelUpDownArrow = ["YOUR_DATA", "YOUR_DATA_DETAIL", "CUSTOMER_DETAIL"]
export const intervalOptions = [
//     {
//     name: 'Hourly',
//     key: '1h',
//     active: true,

// }, 
{
    name: 'Daily',
    key: '1d',
    active: true,

}, {
    name: 'Weekly',
    key: '1w',
    active: false,

}, {
    name: 'Monthly',
    key: '1M',
    active: false,

}]
const widgetsData = [{
        "name": "Alerts by Age",
        "label": "Age",
        "isChart": true,
        "active": true,
        "page": [pages.ALERT],
        "chartType": charts.LINE,
        "apiEndpoint": "/transacto/v1/alert/alerts-by-age",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Alerts by Type",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.ALERT],
        "chartType": charts.BAR,
        "apiEndpoint": "/transacto/v1/alert/alerts-by-type",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Alerts by Risk Level",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.ALERT, pages.DASHBOARD],
        "chartType": charts.BAR,
        "apiEndpoint": "/transacto/v1/alert/alerts-by-rl",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Customers by Risk Level",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.CUSTOMER, pages.DASHBOARD],
        "chartType": charts.PIE,
        "apiEndpoint": "/transacto/v1/customer/get-agg/customer-rl",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],

    },

    {
        "name": "Alerts by Geo Location",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [], //removed from dashboard for now
        "chartType": charts.PIE,
        "apiEndpoint": "/transacto/v1/alert/alerts-by-geographical",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Alerts by Time Period",
        "label": false,
        "isChart": true,
        "active": false,
        "page": pages.ALERT,
        "chartType": charts.PIE,
        "apiEndpoint": "",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Customers by Age",
        "label": "Age",
        "isChart": true,
        "active": false, // removing 6for tem
        "page": [pages.CUSTOMER],
        "chartType": charts.LINE,
        "apiEndpoint": "/transacto/v1/customer/get-agg/age",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Customers by Type",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.CUSTOMER, pages.DASHBOARD],
        "chartType": charts.BAR,
        "apiEndpoint": "/transacto/v1/customer/get-agg/type",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Customers by Geo Location",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.DASHBOARD, pages.CUSTOMER],
        "chartType": charts.PIE,
        "apiEndpoint": "/transacto/v1/customer/get-agg/geographical",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],

    },
    {
        "name": "Transactions by Transfer Type",
        "label": false,
        "isChart": true,
        "active": true,
        "isNew": true,
        "page": [pages.DASHBOARD, pages.TRANSACTION],
        "chartType": charts.BAR,
        "apiEndpoint": "/transacto/v1/insight/get-transaction?time=year&dataType=remitterAccountType",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Transactions by Location",
        "label": false,
        "isChart": true,
        "active": true,
        "page": [pages.DASHBOARD, pages.TRANSACTION],
        "chartType": charts.PIE,
        "apiEndpoint": "/transacto/v1/transaction/visualize/geographical",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],

    },


    {
        "name": "Hourly Trend",
        "label": "Transactions",
        "isChart": true,
        "active": true,
        "page": [pages.TRANSACTION],
        "chartType": charts.LINE,
        "apiEndpoint": "/transacto/v1/transaction/visualize/daily-t",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],
    },
    {
        "name": "Transactions by Transfer Type",
        "label": false,
        "isChart": true,
        "active": false,
        "page": pages.TRANSACTION,
        "chartType": charts.PIE,
        "apiEndpoint": "/transacto/v1/transaction/visualize/transfer-type/?month=3",
        "chartOptions": [
            "pie",
            "bar",
            "doughnut",
            "line"
        ],

    }

]

export default widgetsData;