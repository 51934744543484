import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordion-table',
  templateUrl: './accordion-table.component.html',
  styleUrls: ['./accordion-table.component.scss']
})
export class AccordionTableComponent implements OnInit,OnChanges {
  @Input() tableData;
  @Input() showHeader?;
  @Input() present?;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.tableData?.currentValue){
      this.tableData = changes.tableData.currentValue
    }
  }

  ngOnInit(): void {
  }

}
