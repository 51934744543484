import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'skeleton',
  templateUrl: './kyc-skeleton.component.html',
  styleUrls: ['./kyc-skeleton.component.scss']
})
export class KycSkeletonComponent implements OnInit {
  @Input() type='line' ;
  @Input() animation = 'progress';
  @Input() style;
  @Input() count = 1;
  theme: string = null;
  css: Object = {}

  constructor(private _data: DataService,) { }

  ngOnInit(): void {
    this._data.currentTheme.subscribe(res => {
      if (res) {
        this.theme = res
      }
      else {
        
        this.theme = localStorage.getItem('currentTheme');
      }
      if (this.theme === "theme-dark") {
        this.animation = 'progress-dark'
      }
      // Default bg color set, pass from template to override it
      this.css = { 'background-color': this.theme !== 'theme-dark' ? '#f2f2f2' : '#253138', 'border-radius': '12px', border: '1px solid transparent', ...this.style }
    })


  }

}
