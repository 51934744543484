
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResolutionService {

  constructor(private http: HttpClient, @Inject('TM_API_URL') public TM_API_URL: any) { }
  public alert = new BehaviorSubject<any>('');
  alertVal(value) {
    this.alert.next(value);
  }
  getAlertList(payload) {
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/client/filter`, payload).pipe(map((res) => res['data']));
  }
  getRelatedAlerts(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/similar`, payload).pipe(map((res) => res['data']));
  }
  updateStatus(payload){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update/status`, payload)
  }
  updateAssignee(payload){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update/assigned-to`, payload)
  }
  getAssigneeList(){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/clientsuser/all?page=0&size=50&sortOrder=asc`)
  }
  getHistory(id){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert-history/get/${id}`, {
      page: 0,
      size: 100
    })
  }
  getAlertsQueueList(){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/user-alert/open-alerts-count`)
  }
  getFilter(type,payload){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/filter-by-status/${type}`, payload)
  }

  changeStatusOfAlert(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update-multiple/status`, payload)
  }

  assignTheDataOfAlert(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/update-multiple/assigned-to`, payload)
  }

  getSarReportInfo(guid: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/sar/${guid}`);
  }

  generateSarReport(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/sar/generate`, payload);
  }

  sentEmailReport(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/sar/send-email`, payload);
  }

  downloadSarReport(guid: string){
    return this.http
      .get(`${this.TM_API_URL}/transacto/v1/report/sar/${guid}`, {
          headers: new HttpHeaders({
              'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
      })
      .pipe(
          map((res) => {
              return res;
          })
      );
  }

}
