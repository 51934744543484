import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-risk-screening-guage-chart',
  templateUrl: './risk-screening-guage-chart.component.html',
  styleUrls: ['./risk-screening-guage-chart.component.scss']
})
export class RiskScreeningGuageChartComponent implements OnChanges {
  @Input() score: number = 0;
  @Input() label: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
