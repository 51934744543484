import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChartsModule } from "ng2-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { PrettyJsonModule } from "angular2-prettyjson";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";

import { FormsModule } from "@angular/forms";
import { KycModalComponent } from "./kyc-modal.component";
import { CreateUserComponent } from "./../../components/create-user/create-user.component";

import { FormValidatorModule } from "./../../directives/form-validator/form-validator.module";
import { PipesModule } from "./../../pipes/pipes.module";

import { ImageCropperModule } from "ngx-image-cropper";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
  declarations: [
    KycModalComponent,
    CreateUserComponent,
  ],
  imports: [
    FormsModule,
    PipesModule,
    ChartsModule,
    NgxChartsModule,
    FormValidatorModule,
    ImageCropperModule,
    CommonModule,
    BrowserModule,
    TabsModule,
    NgMultiSelectDropDownModule,
    PrettyJsonModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [KycModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class KycModalModule {}
