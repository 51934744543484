import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor , HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import {DataService} from "../services/data.service";
// import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
        private _data : DataService,
        private _router : Router
        // private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        if(err && err["status"] == 401){
          this._data.toastrShow("Please Log In to Continue" , "error");
          this._data.removeAllCookie();
          this._router.navigate(['/login']);
        }
      }

      if (err.status == 401) {
        // auto logout if 401 response returned from api
        // this.authenticationService.logout();
        this._data.toastrShow("Please Log In to Continue" , "error");
        this._data.removeAllCookie();
        // this._data.changeLoaderVisibility(false);
        this._router.navigate(['/login']);
        // return;
        // location.reload(true);
      }
            
      if(err.status !== 404 && err.status !== 307){
        const error = err.error.message || err.statusText || err["message"];
        this._data.toastrShow(error , "error");
        this._data.changeLoaderVisibility(false);
      }

      if(err.status == 307){
        const error = err.error.message || err.statusText || err["message"];
        this._data.toastrShow(error , "warning");
        this._data.changeLoaderVisibility(false);
        return throwError(err);
      }
      else{
        const error = err.error.message || err.statusText || err["message"];
        return throwError(error);
      }

            
    }))
  }
}