import { Directive , HostBinding , HostListener , EventEmitter , Output} from '@angular/core';
import { DataService } from './../../services/data.service' ;

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

	@Output() private filesChangeEmiter : EventEmitter<FileList> = new EventEmitter();
	@HostBinding('style.background') private background = '#eee';

	extAllowed : any[] = [ "image/png" , "image/jpeg" , "image/jpg" ] ;

	constructor(public _data : DataService){ }

	@HostListener('dragover', ['$event']) public onDragOver(evt){
	  evt.preventDefault();
	  evt.stopPropagation();
	  this.background = '#999';
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt){
	  evt.preventDefault();
	  evt.stopPropagation();
	  this.background = '#eee'
	}

	@HostListener('drop', ['$event']) public onDrop(evt){
	  console.log("Up Here in DND");
	  evt.preventDefault();
	  evt.stopPropagation();
	  let files = evt.dataTransfer.files;
	  if(files.length > 0){
	    this.background = '#eee';
	    console.log("Emitted");
	    console.log(files);
	    // if(files[0] && this.extAllowed.indexOf(files[0].type) >= 0){
	    if(files && files[0]){
	      this.filesChangeEmiter.emit(files);
	    }
	    // }
	    // else{
	    // this._data.toastrShow("File extension not supported , we support png , jpeg & jpg." ,  "error");
	    // }
	  }
	}

}
