import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-nested-table',
  templateUrl: './nested-table.component.html',
  styleUrls: ['./nested-table.component.scss','./../relation-ship-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0',opacity:'0',zIndex:'-100'})),
      state('expanded', style({height: '*',opacity:'1',zIndex:'1'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NestedTableComponent implements OnInit,OnChanges {
  @Input() tableData;
  expanded: {[key: string]: boolean} = {};
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.tableData.currentValue){
      this.tableData = changes.tableData.currentValue;
    }
  }
  ngOnInit(): void {
  }

  isRowClickable(rowIndex: number): boolean {
    return this.tableData[rowIndex].children && this.tableData[rowIndex].children.length > 0
  }

}
