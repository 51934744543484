const themeData = {
    defaultTheme: {
        id: 3,
        data: {

            // Light Mode New Theme SetUp

            "--bg-1": "#C6DDE3",
            "--bg-2": "#E4EFF2",
            "--kyc-stroke": "#B6D1DA",
            "--primary-card": "#FFFFFF",
            "--text-grey": "#B4B4B4",
            "--text-dark": "#3D3D3D",
            "--text-extra-dark": "#E1EEF9",
            "--menu-active-color": "#E54D0C",
            "--menu-bg-color": "#216F88",
            "--menu-text-color": "#FFFFFF",
            "--menu-button-color": "#FFFFFF",
            "--menu-logo-color": "#FFFFFF",

            // Dark Mode New Theme SetUp

            "--dark-bg-1": "#202930",
            "--dark-bg-2": "#242D34",
            "--dark-kyc-stroke": "#32424E",
            "--dark-primary-card": "#1D2328",
            "--dark-text-grey": "#BABCCC",
            "--dark-text-dark": "#E6E7F1",
            "--dark-text-extra-dark": "#212126",
            "--dark-menu-active-color": "#E54D0C",
            "--dark-menu-bg-color": "#216F88",
            "--dark-menu-text-color": "#FFFFFF",
            "--dark-menu-button-color": "#FFFFFF",
            "--dark-menu-logo-color": "#FFFFFF",

            // Common Color Code

            "--primary-theme": "#00BDE3",
            "--secondary-theme": "#216F88",
            "--button-color": "#FFFFFF",
            "--inactive-color": "#727272",
            "--inactive-bg-color": "#00000033",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--transparent-bg": "transparent",
           
        },
    },
    theme1: {
        id: 5,
        data: {
            
            // Light Mode New Theme SetUp

            "--bg-1": "#C4DBDB",
            "--bg-2": "#E0EFEF",
            "--kyc-stroke": "#B2D2D2",
            "--primary-card": "#FFFFFF",
            "--text-grey": "#727272",
            "--text-dark": "#3D3D3D",
            "--text-extra-dark": "#E1EEF9",
            "--menu-active-color": "#E54D0C",
            "--menu-bg-color": "#008282",
            "--menu-text-color": "#FFFFFF",
            "--menu-button-color": "#FFFFFF",
            "--menu-logo-color": "#FFFFFF",

            // Dark Mode New Theme SetUp

            "--dark-bg-1": "#202930",
            "--dark-bg-2": "#242D34",
            "--dark-kyc-stroke": "#32424E",
            "--dark-primary-card": "#1D2328",
            "--dark-text-grey": "#BABCCC",
            "--dark-text-dark": "#E6E7F1",
            "--dark-text-extra-dark": "#212126",
            "--dark-menu-active-color": "#E54D0C",
            "--dark-menu-bg-color": "#008282",
            "--dark-menu-text-color": "#FFFFFF",
            "--dark-menu-button-color": "#FFFFFF",
            "--dark-menu-logo-color": "#FFFFFF",

            // Common Color Code
            
            "--primary-theme": "#059C9C",
            "--secondary-theme": "#008282",
            "--button-color": "#FFFFFF",
            "--inactive-color": "#727272",
            "--inactive-bg-color": "#00000033",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--transparent-bg": "transparent",
          
        },
    },
    theme2: {
        id: 6,
        data: {
                        
            // Light Mode New Theme SetUp

            "--bg-1": "#EDE0DF",
            "--bg-2": "#F9F3F2",
            "--kyc-stroke": "#EED9D7",
            "--primary-card": "#FFFFFF",
            "--text-grey": "#727272",
            "--text-dark": "#3D3D3D",
            "--text-extra-dark": "#E1EEF9",
            "--menu-active-color": "#F27E76",
            "--menu-bg-color": "#1572AA",
            "--menu-text-color": "#FFFFFF",
            "--menu-button-color": "#FFFFFF",
            "--menu-logo-color": "#FFFFFF",

            // Dark Mode New Theme SetUp

            "--dark-bg-1": "#202930",
            "--dark-bg-2": "#242D34",
            "--dark-kyc-stroke": "#32424E",
            "--dark-primary-card": "#1D2328",
            "--dark-text-grey": "#7F8B97",
            "--dark-text-dark": "#B7C5D1",
            "--dark-text-extra-dark": "#212126",
            "--dark-menu-active-color": "#F27E76",
            "--dark-menu-bg-color": "#1572AA",
            "--dark-menu-text-color": "#FFFFFF",
            "--dark-menu-button-color": "#FFFFFF",
            "--dark-menu-logo-color": "#FFFFFF",

            // Common Color Code
            
            "--primary-theme": "#F27E76",
            "--secondary-theme": "#1572AA",
            "--button-color": "#FFFFFF",
            "--inactive-color": "#727272",
            "--inactive-bg-color": "#00000033",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--transparent-bg": "transparent",
          
        },
    },
    LegacyTheme: {
        id: 1,
        data: {
                                    
            // Light Mode New Theme SetUp

            "--bg-1": "#BAB4B4",
            "--bg-2": "#E7E3DF",
            "--kyc-stroke": "#C6BFB8",
            "--primary-card": "#F6EFE8",
            "--text-grey": "#6D6A67",
            "--text-dark": "#31302E",
            "--text-extra-dark": "#272623",
            "--menu-active-color": "#FFFFFF",
            "--menu-bg-color": "#F6EFE8",
            "--menu-text-color": "#1A1818",
            "--menu-button-color": "#BAB4B4",
            "--menu-logo-color": "#31302E",

            // Dark Mode New Theme SetUp

            "--dark-bg-1": "#253138",
            "--dark-bg-2": "#354650",
            "--dark-kyc-stroke": "#475E6C",
            "--dark-primary-card": "#2F3E47",
            "--dark-text-grey": "#98A6AD",
            "--dark-text-dark": "#E2E9ED",
            "--dark-text-extra-dark": "#FFFFFF",
            "--dark-menu-active-color": "#E54D0C",
            "--dark-menu-bg-color": "#2F3E47",
            "--dark-menu-text-color": "#98A6AD",
            "--dark-menu-button-color": "#354650",
            "--dark-menu-logo-color": "#FFFFFF",

            // Common Color Code
            
            "--primary-theme": "#E54D0C",
            "--secondary-theme": "#0075FF",
            "--button-color": "#FFFFFF",
            "--inactive-color": "#ffffff",
            "--inactive-bg-color": "#00000033",
            "--text-success-color": "#17B18C",
            "--text-danger-color": "#D84040",
            "--text-warning-color": "#ED7D15",
            "--red-alert-color": "#D84040",
            "--transparent-bg": "transparent",
  
        }
    }
};

// const p = []
// for (let [key, value] of Object.entries(themeData.defaultTheme.data)) {
//     p.push({ key, value, name: key.slice(2, key.length) })
// }
// const jsonData = JSON.stringify(p, null, 2);
// console.log(jsonData)
export default themeData;