import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { pages } from "../../../assets/constants/insights-dummy";
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-details-2.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit,OnDestroy {
  public graphPage = pages;
  @Input() CustData: any = null;
  @Input() isHyperLink?: boolean = false;
  @Output() back = new EventEmitter();
  showData:boolean=false;
  public canvasWidth = 200
  public needleValue = 0
  public crrScore=0;
  public crrLabel='0'
  public options = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#17B18C', '#ED7D15', '#D84040'],
      arcDelimiters: [30,50],
      rangeLabel: ['0', '100'],
      needleStartValue: 0,
  }
  transactionArray: any = []
  page: number = 1;
  total: number = 0;
  size: number = 10
  isCardView: boolean = true;
  attributesList: any[] = [
    { name: 'Transaction ID', slug: 'transaction-id', value: true},
    { name: 'Transaction Status', slug: 'transaction-status', value: false},
    { name: 'Remitter Account Number', slug: 'remitter-account-number', value: false},
    { name: 'Remitter Name', slug: 'remitter-name', value: true},
    { name: 'Remitter ID Number', slug: 'remitter-id-number', value: false},
    { name: 'Remitter Bank Name', slug: 'remitter-bank-name', value: false},
    { name: 'Remitter Bank Country', slug: 'remitter-bank-country', value: false},
    { name: 'Beneficiary Account Number', slug: 'beneficiary-account-number', value: false},
    { name: 'Beneficiary Name', slug: 'beneficiary-name', value: true},
    { name: 'Beneficiary ID Number', slug: 'beneficiary-id-number', value: false},
    { name: 'Beneficiary Bank Name', slug: 'beneficiary-bank-name', value: false},
    { name: 'Beneficiary Bank Country', slug: 'beneficiary-bank-country', value: false},
    { name: 'Transaction Date', slug: 'transaction-date', value: false, date: true},
    { name: 'Amount Transferred', slug: 'amount-transferred', value: true},
    { name: 'Mode of Transaction', slug: 'mode-of-Transaction', value: true},
    { name: 'Transfer Type', slug: 'transfer-type', value: false},
    { name: 'Transaction Initiation Country', slug: 'transaction-initiation-country', value: false},
  ];
  customerGuid: any;
  crrData: any[] = [];
  theme:any = 'theme-light';
  activityLog: boolean = true;
  transactionFullHeight: boolean = false;
  ruleFullHeight: boolean = false;
  isWidgetFullHeight: boolean = false;
  customerbankDetails: any[] = [];
  customerAccountAttributes: any[] = [
    { name: 'Bank Name', slug: 'bankName' },
    { name: 'Bank Branch Name', slug: 'bankBranchName' },
    { name: 'Account Number', slug: 'accountNumber' },
    { name: 'Account Sharing Type', slug: 'accountSharingType' },
    { name: 'Account Status', slug: 'accountStatus' },
    { name: 'Account Type', slug: 'accountType' },
    { name: 'Bank Branch Address', slug: 'bankBranchAddress' },
    { name: 'Bank Headquarter Country', slug: 'bankHeadquarterCountry', noObj: true }
  ]
  customerLogs: any[] = [];
  sticky: boolean = false;
  tableHeaderCount: number;
  categoryList: any[] = [];
  timeDurationOptions = [
    { name: "Week", value: "1w" },
    { name: "Month", value: "1M" },
    { name: "6 Months", value: "6M" },
    { name: "Year", value: "1y" },
  ];
  timeDuration: string = '1M';
  ruleList = {
    totalPage: 0,
    page: 1,
    size: 10,
    type: 'true',
    list: [],
    total: 0
  };
  ruleVoilatedList = {
    totalPage: 0,
    page: 1,
    size: 10,
    type: 'false',
    list: [],
    total: 0
  };
  alertsSuspiciousData: any;
  trnsactionInvolveData: any;
  rulePreviewArray: { outerBraces: boolean; groups: any[]; };
  trnsactionOutsideData: any;
  velocityData: any;
  crossBorderData: any;
  customerID: any;
  customerDetails: any;
  customerRiskDetails: any;
  customerRelationData: any;
  amlData: any;

  constructor(private _app: AppService, private _data: DataService, private commonService: CommonService, private modal: NgbModal,private location:Location,private route: ActivatedRoute) { }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= 6){
          this.sticky = true;
      } else {
          this.sticky = false;
      }
  }
  ngOnDestroy() {
    localStorage.removeItem('isDetails');
  }
  ngOnInit(): void {
    // Hide Sidebar
    this.route.params.subscribe(params => {
      const { id } = params
      this.customerID = id;
    });
    if(this.customerID){
      this.getCustomerDetails(this.customerID);
      this.getCustomerRiskDetails(this.customerID);
    }
    // var element = document.getElementById("wrapper");
    // let sidebarLogo = document.getElementById('sidebar-logo');
    // let hideLogo = document.getElementById('hide-logo');
    // element.classList.add("forced", "enlarged");
    // this.CustData = localStorage.getItem('isDetails') ? JSON.parse(localStorage.getItem('isDetails')) : this.closeInfo();
    // if(sidebarLogo) sidebarLogo.style.marginLeft = '-12px';
    // if(hideLogo){
    //   hideLogo.style.display = 'none';
    //   this._data.sideBarHiddenVal(true);
    // }
    // Hide Sidebar

    // Calculate total count
    this.tableHeaderCount = this.attributesList.reduce((counter, obj) => {
      if (obj?.value) counter += 1
      return counter;
    }, 0);
    this._data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    });
    // this.getCustomerData()
    // this.fetchTransactionData();
    if(this.CustData?.idNumber){
      this.getAccountList(this.CustData?.idNumber);
      this.getRuleListByCustomerId(this.ruleList, this.CustData?.idNumber);
      this.getRuleListByCustomerId(this.ruleVoilatedList, this.CustData?.idNumber);
      this.pastAlertData(this.CustData?.idNumber);
      this.trnsactionInvolvingPep(this.CustData?.idNumber);
      this.trnsactionOutsideCountry(this.CustData?.idNumber);
      this.trnsactionVelocityData(this.CustData?.idNumber);
      this.trnsactionCrossBorder(this.CustData?.idNumber);
    }
    if(this.CustData?.id) this.getLogs(this.CustData?.id);
  }

  getCustomerDetails(id){
    this._app.getCustomerDetails(id).subscribe(res => {
      if(res){
        console.log(res);
        this.customerDetails = res?.['data'];
        // this.amlData = this.customerDetails?.riskCalculation?.amlResult ? JSON.parse(this.customerDetails?.riskCalculation?.amlResult ) : null;
        this.amlData = this.customerDetails?.riskCalculation?.amlResult
        if(this.amlData){
          console.log(JSON.parse(this.amlData),'amlData')
          let amlGuid = JSON.parse(this.amlData)?.data?.content?.[0]?.guid;
          if(amlGuid){
            // let dum = '989a7d515b22d6ea72b7408d1a3a2f02aa239cad7ca32dcb0f49b1318ae1db02'
            this.getRelationshipData(amlGuid)
          }
        }
       
      }
    })
  }

  getRelationshipData(guid){
    this._app.getCustomerRelationShipDetails(guid).subscribe(res => {
      if(res){
        this.customerRelationData = res?.['data']?.['data'];
      }
    })
  }

  getCustomerRiskDetails(id){
    this._app.getCustomerRiskDetails(id).subscribe(res => {
      if(res){
        this.customerRiskDetails = res?.['data'];
      }
    })
  }

  pastAlertData(customerId){
    this.commonService.pastAlertData(customerId).subscribe((res) => this.alertsSuspiciousData = res);
  }

  trnsactionInvolvingPep(customerId){
    this.commonService.trnsactionInvolvingPep(customerId).subscribe((res) => {
      this.trnsactionInvolveData = res;
      if(this.trnsactionInvolveData?.totalInvolvedCount && this.trnsactionInvolveData?.totalCount && this.trnsactionInvolveData?.totalCount != 0){
        let percentage = (this.trnsactionInvolveData?.totalInvolvedCount/this.trnsactionInvolveData?.totalCount)*100;
        percentage = Math.round(percentage);
        this.trnsactionInvolveData['percentage'] = `${percentage}%`
      }
    });
  }

  trnsactionOutsideCountry(customerId){
    this.commonService.trnsactionOutsideCountry(customerId).subscribe((res) => {
      this.trnsactionOutsideData = res;
      if(this.trnsactionOutsideData?.totalOutSideTransaction && this.trnsactionOutsideData?.totalTransaction && this.trnsactionOutsideData?.totalTransaction != 0){
        let percentage = (this.trnsactionOutsideData?.totalOutSideTransaction/this.trnsactionOutsideData?.totalTransaction)*100;
        percentage = Math.round(percentage);
        this.trnsactionOutsideData['percentage'] = `${percentage}%`
      }
    });
  }

  trnsactionVelocityData(customerId){
    this.commonService.trnsactionVelocityData(customerId).subscribe((res) => this.velocityData = res);
  }

  trnsactionCrossBorder(customerId){
    this.commonService.trnsactionCrossBorder(customerId).subscribe((res) => this.crossBorderData = res);
  }

  getAccountList(customerId){
    const payload = {
      "order": "ASC",
      "orderBy": "string",
      "page": 0,
      "size": 0
    }
    this.commonService.getAccountList(customerId, payload).subscribe((res) => {
      if(res && res?.length){
        res.forEach((item) => {
          let account = { bankDetails: [] }
          for(let [key] of Object.entries(item)){
            this.customerAccountAttributes.forEach((e) => {
              if(e?.slug == key){
                let obj = { key: '', value: '' }
                obj['key'] = e?.name;
                if(e?.noObj){
                  obj['value'] = item[key]?.name;
                }
                else{
                  obj['value'] = item[key];
                }
                account?.bankDetails.push(obj);
              }
            });
          }
          this.customerbankDetails.push(account);
        })
      }
    });
  }

  getRuleListByCustomerId(object: Object, customerId){
    const payload = {
      page: object['page'] - 1,
      size: object['size'],
      order: "DESC",
      orderBy: "createdDate",
      customerId: customerId
    }
    this.commonService.getRuleListByCustomerId(payload, object['type']).subscribe((res) => {
      if(object['type'] == 'false'){
        this.ruleVoilatedList['list'] = res['content'] || [];
        this.ruleVoilatedList['total'] = res['totalElements'] || 0;
        this.ruleVoilatedList['totalPage'] = res['totalPages'] || 0;
      }
      else if(object['type'] == 'true'){
        this.ruleList['list'] = res['content'] || [];
        this.ruleList['total'] = res['totalElements'] || 0;
        this.ruleList['totalPage'] = res['totalPages'] || 0;
      }
    });
  }

  handleIncrease(type: string){
    if(type == 'true'){
      if(this.ruleList['page'] < this.ruleList['totalPage']){
        this.ruleList['page'] += 1;
        this.getRuleListByCustomerId(this.ruleList, this.CustData?.idNumber);
      }
    }
    else if(type == 'false'){
      if(this.ruleVoilatedList['page'] < this.ruleVoilatedList['totalPage']){
        this.ruleVoilatedList['page'] += 1;
        this.getRuleListByCustomerId(this.ruleVoilatedList, this.CustData?.idNumber);
      }
    }
  }

  handleDecrease(type: string){
    if(type == 'true'){
      if(this.ruleList['page'] > 1){
        this.ruleList['page'] -= 1;
        this.getRuleListByCustomerId(this.ruleList, this.CustData?.idNumber);
      }
    }
    else if(type == 'false'){
      if(this.ruleVoilatedList['page'] > 1){
        this.ruleVoilatedList['page'] -= 1;
        this.getRuleListByCustomerId(this.ruleVoilatedList, this.CustData?.idNumber);
      }
    }
  }

  closeInfo() {
    this.back.emit();
    this.location.back()
  }

  getWidgetClose(event){
    if(event == 'close-widget'){
      this.isWidgetFullHeight = !this.isWidgetFullHeight;
    }
  }

  getAgeByDob(dob){
    if(dob){
      var birthday = +new Date(dob);
      return ~~((Date.now() - birthday) / (31557600000));
    }
    return null
  }
  
  getCustomerData() {
    let apiUrl;
    this.CustData?.accountNumber ? apiUrl = `transacto/v1/customer?accNum=${this.CustData?.accountNumber}&cust-id=${this.CustData?.idNumber}` : apiUrl = `transacto/v1/customer?cust-id=${this.CustData?.idNumber}`;
    this._app.getCustomerRiskData(apiUrl).subscribe(res => {
      this.CustData={...this.CustData,age:this.CustData.age || this.getAgeByDob(this.CustData.dob)}
      const response: any = res
      if (response.data.riskCalculation) {
        let crrStr = response.data.riskCalculation.crrResult;
        let amlResults;
        if(response.data.riskCalculation.amlResult) amlResults = JSON.parse(response.data.riskCalculation.amlResult);
        const crrObj = JSON.parse(crrStr);
        if(amlResults && amlResults?.data['content'] && amlResults?.data['content']?.length){
          this.customerGuid = amlResults?.data['content'][0]?.guid;
          this.categoryList = amlResults?.data['content'][0]?.masterData?.category;
        }
        this.CustData = {
          ...this.CustData,
          crrScore: crrObj.crrScore.toFixed(2) || '-',
          amlValue: crrObj.amlLabel || '-',
          geography: response?.data?.riskCalculation?.geoLocationScore || '-',
          adverseMedia: response?.data?.riskCalculation?.adverseMedia || '-',
          riskLabel: crrObj.crrScore.toFixed(2) < 30 ? "LOW RISK" : crrObj.crrScore < 50 ? "MEDIUM RISK" : "HIGH RISK",
        }
        this.crrScore = this.CustData.crrScore;
        this.crrLabel = String(this.CustData.crrScore);
      }
      this.showData = true
    }, 
    (err => {
      this.showData = true;
    })
    )
  }

  toggleView(){
    if(!this.isCardView){
      this.isCardView = true;
    }
    else{
      this.isCardView = false;
    }
  }

  fetchTransactionData() {
    const payload = {
      "pageNo": this.page - 1,
      "size": 10
    }
    this._data.changeLoaderVisibility(true)
    this._app.getAllTransactionDataByCustomerId(this.CustData.idNumber, payload).subscribe(res => {
      this._data.changeLoaderVisibility(false)
      const response: any = res
      this.total = response.data.totalElements
      this.transactionArray = [...response.data.content.map(e => {

        const getMonthInEnglish = (m) => {
          switch (m) {
            case 0:
              return 'January'

            case 1:
              return 'Febuary'

            case 2:
              return 'March'

            case 3:
              return 'April'

            case 4:
              return 'May'

            case 5:
              return 'June'

            case 6:
              return 'July'

            case 7:
              return 'August'

            case 8:
              return 'September'

            case 9:
              return 'October'

            case 10:
              return 'November'

            case 11:
              return 'December'

            default:
              break
          }

        }
        const transactionDate = new Date(e.dateOfTransaction)
        const date = transactionDate.getDate() + " " + getMonthInEnglish(transactionDate.getMonth()) + ', ' + transactionDate.getFullYear()
        const time = transactionDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
        return ({
          ...e, date, time,
        })




      })];
      this.updateTableValue();
    }, (err: HttpErrorResponse) => {
      let alertObj = {
        message: err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this._data.changeLoaderVisibility(false)
      this._data.toastrShow(alertObj.message, alertObj.status)
    })

  }

  // Tbale header limit upto 7

  tableHeaderLimit(item){
    if(item?.value){
      this.tableHeaderCount += 1;
    }
    else{
      this.tableHeaderCount -= 1;
    }

    if(this.tableHeaderCount > 7){
      item.value = false;
    }
    else{
      this.updateTableValue();
    }
  }

  showLimitPopup(){
    if(this.tableHeaderCount >= 7) this._data.toastrShow('You can select upto 7 attributes', 'info');
  }

  updateTableValue(){
    if(this.transactionArray?.length){
      this.transactionArray.forEach((e) =>{
        const rowData = [...this.attributesList.map((data) => {
          if(data.slug == 'transaction-id'){
            data['rowData'] = e?.transactionId;
          }
          else if(data.slug == 'transaction-status'){
            data['rowData'] = e?.transactionStatus;
          }
          else if(data.slug == 'remitter-account-number'){
            data['rowData'] = e?.remitterAccountNumber;
          }
          else if(data.slug == 'remitter-name'){
            data['rowData'] = e?.remitterName;
          }
          else if(data.slug == 'remitter-id-number'){
            data['rowData'] = e?.remitterIdNumber;
          }
          else if(data.slug == 'remitter-bank-name'){
            data['rowData'] = e?.remitterBankName;
          }
          else if(data.slug == 'remitter-bank-country'){
            data['rowData'] = e?.remitterBankCountryName;
          }
          else if(data.slug == 'beneficiary-account-number'){
            data['rowData'] = e?.beneficiaryAccountNumber;
          }
          else if(data.slug == 'beneficiary-name'){
            data['rowData'] = e?.beneficiaryName;
          }
          else if(data.slug == 'beneficiary-id-number'){
            data['rowData'] = e?.beneficiaryIdNumber;
          }
          else if(data.slug == 'beneficiary-bank-name'){
            data['rowData'] = e?.beneficiaryBankName;
          }
          else if(data.slug == 'beneficiary-bank-country'){
            data['rowData'] = e?.remitterBankCountryName;
          }
          else if(data.slug == 'transaction-date'){
            data['rowData'] = e?.dateOfTransaction;
          }
          else if(data.slug == 'amount-transferred'){
            data['rowData'] = e?.amountTransferred;
          }
          else if(data.slug == 'mode-of-Transaction'){
            data['rowData'] = e?.modeOfTransaction;
          }
          else if(data.slug == 'transfer-type'){
            data['rowData'] = e?.transferType;
          }
          else if(data.slug == 'transaction-initiation-country'){
            data['rowData'] = e?.transactionInitiationCountry;
          }
          return {
            ...data
          }
        })
      ]
        e['attributeList'] = rowData;
      })
    }
  }
  pageChange(e) {
    this.page = e

    this.fetchTransactionData()
  }

  // CRR Break Points

  crrBreakPoint(TemplateRef){
    if(this.customerGuid){
      const payload = {
        guid: this.customerGuid
      }
      this._data.changeLoaderVisibility(true);
      this.commonService.getCrrBreakPoints(payload).subscribe((res) =>{
        this.modal.open(TemplateRef, {
          ariaLabelledBy: "modal-basic-title",
          size: "md",
          backdrop: "static",
          modalDialogClass:
            this.theme === "theme-dark" ? "dark-modal" : "light-modal",
        });
        this._data.changeLoaderVisibility(false);
        if(res && res?.content && res?.content?.length){
          this.crrData = res?.content[0]?.riskProfile?.riskFactor || [];
        }
      })
    }
  }

  removeDefaultOr(ruleArray){
    // Manipulate the data as per requirements
    const fetchGroup = [...ruleArray.map((group) => {
      const scenarios = group.scenarios.map((scs) => {
        if(scs?.selectedType == 'User_Input'){
          scs['inputValue'] = scs?.eq ? scs?.eq : scs?.let ? scs?.lte : scs?.gte;
          scs['operator'] = scs?.eq ? 'EQ' : scs?.let ? 'LTE' : 'GTE';
        }
        return scs
      });
      return {
        ...group, scenarios
      }
    })];

    // Remove the default OR
    for(let i = 0; fetchGroup?.length > i; i++){
      if(i < fetchGroup?.length -1){
        fetchGroup[i]['connector'] = fetchGroup[i+1]['connector'];
      }
      else{
        fetchGroup[i]['connector'] = '';
      }
      for(let j = 0; fetchGroup[i].scenarios?.length > j; j++){
        if(j < fetchGroup[i].scenarios?.length -1){
          fetchGroup[i].scenarios[j]['connector'] = fetchGroup[i].scenarios[j+1]['connector'];
        }
        else{
          fetchGroup[i].scenarios[j]['connector'] = '';
        }
      }
    }
    return fetchGroup
  }

  // Rule Query

  ruleQueryModal(ruleGroup, TemplateRef){
    if(ruleGroup?.length){
      this.modal.open(TemplateRef, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        backdrop: "static",
        modalDialogClass:
          this.theme === "theme-dark" ? "dark-modal" : "light-modal",
      });
      
      const fetchGroup = this.removeDefaultOr(ruleGroup);
      var ruleDataArray = [];
      ruleDataArray = fetchGroup;
      var ruleData = { outerBraces : false, groups: ruleDataArray }
      if(ruleDataArray?.length > 1) ruleData['outerBraces'] = true;
      this.rulePreviewArray = ruleData;
      }
  }

  // Customer Logs

  getLogs(customerId: string){
    const payload = {
      "customerId": customerId, 
      "order": "DESC", 
      "orderBy": null, 
      "page": 0, 
      "size": 0
    }
    this._data.changeLoaderVisibility(true);
    this.commonService.getCustomerLogs(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe((res) => {
      if(res && res?.length){
        for (var i = 1; res?.length > i; i++) {
          if(res[i]?.date){
            let formate1 = new DatePipe('en-Us').transform(res[i]?.date, 'dd-MM-yyyy');
            let formate2 = new DatePipe('en-Us').transform(res[i - 1]?.date, 'dd-MM-yyyy')
            if (formate1 == formate2) {
              res[i]['sameDate'] = 'yes'
            }
          }
        }
        this.customerLogs = res;
      }
    });
  }

}
