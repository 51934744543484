import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AppService } from "../../../services/app.service";
import { DataService } from "../../../services/data.service";
import  {
  charts,
  pages,
  intervalOptions,
  entities,
  viewCustomiseInsightsButtons,
  insightPannelUpDownArrow
} from "../../../../assets/constants/insights-dummy";
import {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
  Chart
} from "chart.js";
import { ThemeService, BaseChartDirective } from "ng2-charts";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import {$hintColor} from '../../../../assets/scss/variables';

@Component({
  selector: "app-widgets",
  templateUrl: "./widgets.component.html",
  styleUrls: ["./widgets.component.scss"],
})
export class WidgetsComponent implements OnInit {
  @ViewChild( BaseChartDirective ) chart: BaseChartDirective;
  @Input() pageName;
  @Input() pageId?: any; // This is the pageId for some special case and used in view rule page and the setting of the charts will be disabled for this
  @Input() policyId?: any; // This is the policyId for some special case and used in view rule page and the setting of the charts will be disabled for this
  @Input() datasetId?: any; // This is the ppolicyId for some special case and used in view rule page and the setting of the charts will be disabled for this
  @Input() cardsForSkeleton;
  @Output() closeWidget = new EventEmitter<any>();
  insightPannel: boolean = false;
  isChartFullHeight:boolean = false;
  widgetsData: any = [];
  theme: any = null;
  timeDurationOptions = [
    { name: "Week", value: "1w" },
    { name: "Month", value: "1M" },
    { name: "6 Months", value: "6M" },
    { name: "Year", value: "1y" },
  ];
  timeDurationSelected = "1M";
  // labelColor = this.theme === 'light' ? '' :
  defaultPieChartOptions = {
    // cutoutPercentage: 70,
    responsive: true,
    aspectRatio: 1,
    tooltips:{
      xPadding:12,
      yPadding:12,
      borderWidth:0,
      borderColor:undefined,
      titleFontStyle:'lighter',
      titleSpacing:40,
      bodySpacing:12,
      cornerRadius:0,
      titleMarginBottom:8,
      titleFontSize:14,
      titleFontColor:'#fff',
      bodyFontColor:'#fff',
      backgroundColor:'rgba(0,0,0,.4)'
    },
    legend: {
      display: false,
      position: "right",
      labels: {
        fontColor: "#B4B4B4",
        fontSize: 14,
        usePointStyle: true,
      },
    },
  };
  defaultDoughnutChartOptions = {
    cutoutPercentage: 70,
    responsive: true,
    aspectRatio: 1,
    tooltips:{
      xPadding:12,
      yPadding:12,
      borderWidth:0,
      borderColor:undefined,
      titleFontStyle:'lighter',
      titleSpacing:40,
      bodySpacing:12,
      cornerRadius:0,
      titleMarginBottom:8,
      titleFontSize:14,
      titleFontColor:'#fff',
      bodyFontColor:'#fff',
      backgroundColor:'rgba(0,0,0,.4)'
    },
    legend: {
      display: false,
      position: "right",
      labels: {
        fontColor: "#B4B4B4",
        fontSize: 14,
        usePointStyle: true,
      },
    },
  };
  defaultBarChartOptions = {
    // cutoutPercentage: 70,
    aspectRatio: 1.75,
    responsive: true,
    tooltips:{
      xPadding:12,
      yPadding:12,
      borderWidth:0,
      borderColor:undefined,
      titleFontStyle:'lighter',
      titleSpacing:40,
      bodySpacing:12,
      cornerRadius:0,
      titleMarginBottom:8,
      titleFontSize:14,
      titleFontColor:'#fff',
      bodyFontColor:'#fff',
      backgroundColor:'rgba(0,0,0,.4)'
    },
    scales: {
      x: {},
      y: {
        // min: 0
      },
      xAxes: [
        {
          display: true,
          barPercentage: 0.5,
          ticks: {
            fontColor: "#b4b4b4",
            maxRotation:0,
            minRotation: 0,
            maxTicksLimit: 2,
          },
          gridLines: {
            display: false,
          },
          // type: 'time',
          // time: {
          //   unit: 'month'
          // }
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            fontColor: "#b4b4b4",
            maxTicksLimit: 6,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        color: "#fff",
        fontSize: 14,
        usePointStyle: true,
      },
    },

    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };
  defaultLineChartOptions = {
    responsive: true,
    aspectRatio: 1.75,
    legend: {
      display: false,
      position: "right",
      labels: {
        fontColor: "#B4B4B4",
        fontSize: 14,
        usePointStyle: true,
      },
    },
    tooltips:{
      xPadding:12,
      yPadding:12,
      borderWidth:0,
      borderColor:undefined,
      titleFontStyle:'lighter',
      titleSpacing:40,
      bodySpacing:12,
      cornerRadius:0,
      titleMarginBottom:8,
      titleFontSize:14,
      titleFontColor:'#fff',
      bodyFontColor:'#fff',
      backgroundColor:'rgba(0,0,0,.4)'
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            fontColor: "#b4b4b4",
            labelString: "Month",
          },
          ticks: {
            fontColor: "#b4b4b4",
            count: 2,
            crossAlign: "far",
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 2,
            autoSkip: true,
          },
          gridLines: {
            display:false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
            // steps: 8,

            stepValue: 10,
            // max: 100,
            fontColor: "#b4b4b4",
            maxTicksLimit: 6,
          },
        },
      ],
    },

    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  chartColors: any[] = [
    {
      backgroundColor: null,
      borderColor: "transparent",
    },
  ];
  selectedChartData: any = {};
  isLoading: boolean = false;
  cardSize: any = [1, 2, 3,];
  public widgetForm: FormGroup;
  selectedIntervals: any = [];
  chartOptions: any = [];
  entityOptions: any = [];
  attributeOptions: any = [];
  intervalOptions: any = intervalOptions;
  widgetArrForVisibility: any = [];
  newData: any = [];
  newArr: any = [];
  isChartLoading:boolean = false
  widgetIdToDelete:any = null
  public customParamsObject = {
    color: 'red',
 
  };
  slideConfig = {
    slidesToShow:5,
    slidesToScroll: 1,
    vertical:true ,
    verticalSwiping:true,
    infinite:true,
    prevArrow: '<div class="slick-icon-container"><span class="icon" style="align-items:flex-start;"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-up fa-2x" aria-hidden="true"></i></span></div>',
    nextArrow: '<div class="slick-icon-container"><span class="icon" style="align-items:center"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-down fa-2x" aria-hidden="true"></i></span></div>',

  };
  slideConfigWidgetWrap = {
    slidesToShow:3,
    slidesToScroll: 1,
    infinite:false,
    responsive: [
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
    // prevArrow: '<span class="icon" style="align-items:flex-start;"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-up fa-2x" aria-hidden="true"></i></span>',
    // nextArrow: '<span class="icon" style="align-items:center"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-down fa-2x" aria-hidden="true"></i></span>',

  };
  isViewCustomizeInsights: boolean = false;
  txnDateOptions: any = [
    { key: "dateOfTransaction", label: 'Date of Transaction' },
    { key: "createdDate", label: "Created Date" },
    { key: "modifyDate", label: "Modified Date" }]
  customerDateOptions: any = [
    { key: "accountCreatedDate", label: 'Account Created Date' },
    { key: "createdDate", label: "Created Date" },
    { key: "modifyDate", label: "Modified Date" }]
  dateAttributeOptions: any = [];
  windowWidth: number = 0;
  chartCardHeight: HTMLElement;
  cardInitialHeight: number;
  constructor(
    private appService: AppService,
    private _data: DataService,
    private themeService: ThemeService,
    private modal: NgbModal,
    private form: FormBuilder
  ) {
    this.widgetForm = this.form.group({
      name: this.form.control(null, Validators.required),
      description: this.form.control(null, Validators.required),
      chartType: this.form.control(null, Validators.required),
      entity: this.form.control(null, Validators.required),
      attribute: this.form.control(null, Validators.required),
      dateAttribute: this.form.control(null),
    });
    this.chartOptions = Object.keys(charts).map((e) => ({
      name: e,
      value: charts[e],
    }));
    this.entityOptions = Object.keys(entities).map((e) => ({
      name: e,
      value: entities[e],
    }));
  }

  // Media Query for chart height
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    for(let j = 0; j < this.widgetsData?.length; j++){
      this.chartCardHeight = document.getElementById(`chart-card-height${j}`);
      this.cardInitialHeight = 400;
      if(this.windowWidth > 1699 && this.windowWidth < 4001){
        for (let i = 1700; i <= this.windowWidth; i += 100) {
          this.cardInitialHeight += 20;
          this.chartCardHeight.style.height = `${this.cardInitialHeight}px`
        }
      }
    }
  }

  ngOnInit(): void {
    if(viewCustomiseInsightsButtons.includes(this.pageName)){
      this.isViewCustomizeInsights = true;
    }
    if(insightPannelUpDownArrow.includes(this.pageName)){
      this.insightPannel = true;
    }
    this._data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
      // this.gridColor = this.theme === "light" ? '#f2f2f2' : '#253138'
      let overrides: ChartOptions;
      if (this.theme === "theme-dark") {
        overrides = {
          // legend: {
          //   labels: { fontColor: 'white' }
          // },
          scales: {
            xAxes: [
              {
                // ticks: { fontColor: 'white' },
                gridLines: { color: "#253138" },
              },
            ],
            yAxes: [
              {
                // ticks: { fontColor: 'white' },
                gridLines: { color: "#253138" },
              },
            ],
          },
        };
      } else {
        overrides = {
          // legend: {
          //   labels: { fontColor: 'white' }
          // },
          scales: {
            xAxes: [
              {
                // ticks: { fontColor: 'white' },
                gridLines: { color: "#f2f2f2" },
              },
            ],
            yAxes: [
              {
                // ticks: { fontColor: 'white' },
                gridLines: { color: "#f2f2f2" },
              },
            ],
          },
        };
      }
      this.themeService.setColorschemesOptions(overrides);
    });
    // this.getChartApiData(
    //   this.data.filter((e) => e.active && e.page.includes(this.pageName))
    // );

    this.cardSize = Array(this.cardsForSkeleton).fill(0);
    this.getAllInsights(this.pageName);

    // for getting var() css
    // let style = getComputedStyle(document.body)
    // console.log(style.getPropertyValue('primary-button-bg-color'))
  }
  async getAllInsights(page) {
    this.isLoading= true
    const res: any = await this.appService
      .fetchAllInsightsByPage(page)
      .toPromise();
    this.isLoading=false
  
    this.widgetArrForVisibility = res.data.map(({ id, insightsPages, name }) => ({
      id,
      name,
      insightsPages:insightsPages.find((p) => p.insightsPageEnum === page),
      visible:insightsPages.find((p) => p.insightsPageEnum === page).status === "ACTIVE" ? true: false
    }));
    res.data = [...res.data.filter((e)=>e.insightsPages.findIndex((p=>p.insightsPageEnum === page && p.status === 'ACTIVE')) !== -1)]
    this.newData = res.data || [];
    this.widgetsData=res.data
    this.getFormatterDataNew();
  }

  setSelectedChartData(data) {
    this.selectedChartData = Object.assign({},data);
    this.selectedChartData.widgetConfig.aspectRatio = 2;
  }
  resetChartSize(){
    this.selectedChartData.widgetConfig.aspectRatio = this.selectedChartData.chartType === charts.PIE ? 1 : 1.5;
  }
  async setSelectedChartDataForEdit(data) {
    this.selectedChartData = {
      ...data,
      isFilterTrue: data.intervals.length ? "true" : "false",
    };
    this._data.changeLoaderVisibility(true);
    await this.handleEntityChange({ value: data.entity });
    this._data.changeLoaderVisibility(false);
    this.widgetForm.patchValue({
      name: data.name,
      description: data.description,
      chartType: data.chartType,
      entity: data.entity,
      attribute: data.attribute,
      dateAttribute:data.dateAttribute || null
    });
    this.selectedIntervals = this.selectedChartData.intervals.map((i) =>
      intervalOptions.find((e) => e.key === i)
    );
  }
  handleChartLabelColor(i, color) {
    this.selectedChartData.apiData.includedLabelConfig[i].color = color;
  }
  filterIntervals(intervalArr){
    return [...intervalArr.filter(e => e!== '1h')]
  }
  getFormatterDataNew() {
    const promises = this.newData.map(async (w) => {
      this.isChartLoading = true;
      // console.log(99,w.intervals)
      return {
        ...w,
        intervals:this.filterIntervals(w.intervals),
        apiData: await this.getChartDataById(
          w.id,
          this.filterIntervals(w.intervals) && this.filterIntervals(w.intervals)[0] ? this.filterIntervals(w.intervals)[0] : null
        ),
      };
    });
    Promise.allSettled(promises).then((res) => {
      const arr: any = [...res.filter((r) => r.status === "fulfilled")];
      this.widgetsData = [
        ...arr.map(({ value }) => ({
          ...this.getFormattedDataForSingleWidget(value),
          selectedInterval: value.intervals[0],
        })),
      ];
      this.isChartLoading = false;

      // Media Query for chart height
      this.windowWidth = window.innerWidth;
      for(let j = 0; j < this.widgetsData?.length; j++){
        this.chartCardHeight = document.getElementById(`chart-card-height${j}`);
        this.cardInitialHeight = 400;
        if(this.windowWidth > 1699 && this.windowWidth < 4001){
          for (let i = 1700; i <= this.windowWidth; i += 100) {
            this.cardInitialHeight += 20;
            this.chartCardHeight.style.height = `${this.cardInitialHeight}px`
          }
        }
      }
    });
  }
  async getChartDataById(id, interval=null) {
    if(this.pageId){ // this is the condition check whether the pageId is there or not
      var res: any = await this.appService.getWidgetDataByPageId(
        id,
        this.timeDurationSelected,
        interval ? interval : this.getDefaultInterval(),
        this.pageId
      ).toPromise();
    }
    else if(this.policyId && this.datasetId){ // this is the condition check whether the policyId is there or not
      var res: any = await this.appService.getWidgetDataByPolicyId(
        id,
        this.timeDurationSelected,
        interval ? interval : this.getDefaultInterval(),
        this.policyId,
        this.datasetId
      ).toPromise();
    }
    else{
      var res: any = await this.appService.getWidgetDataById(
        id,
        this.timeDurationSelected,
        interval ? interval : this.getDefaultInterval()
      ).toPromise();
    }
    if (res && res.data && res.data.chartData && res.data.chartData.length) {
      return res.data;
    }
    return false;
  }
  getFormattedDataForSingleWidget(w) {
    const getWidgetConfig = (widget) => {
      if (widget.chartData) {
     //   widget.chartData = [...widget.chartData.filter(e=> widget.includedLabelConfig.findIndex(p=>p.name === e.label) !== -1)]
        switch (widget.chartType) {
          case charts.LINE:
            return {
              widgetConfig: { ...this.defaultLineChartOptions },
              widgetData: [
                ...widget.chartData.map((e) => ({
                  ...e,
                  data: e.data.map((p) => p.value),
                })),
              ],
              widgetLabels: [
                ...widget.chartData[0].data.map((s) => s?.timeCode ? s?.key?.slice(0, 10) : s?.key),
              ],
              widgetColors: [
                ...widget.chartData.map((m) => ({
                  backgroundColor: "transparent",
                  borderColor: m.color,
                  pointBackgroundColor: m.color,
                  pointBorderColor: "#f2f2f2",
                })),
              ],
            };
          case charts.BAR:
            const defaultBarChartOptions = { ...this.defaultBarChartOptions }
            if(widget?.attribute == 'riskFactor'){
              defaultBarChartOptions['scales']['xAxes'][0]['display'] = false;
            }
            return {
              widgetConfig: { ...defaultBarChartOptions },
              widgetData: [
                ...widget.chartData.map((e) => ({
                  ...e,
                  data: e.data.map((p) => p.value),
                })),
              ],
              // widgetData:[{
              //   label:'test',
              //   data: [{id:'11',value:1500}, {id:'12',value:500}],
              //   parsing:{
              //     yAxisKey:'value',
              //     xAxisKey:'id'
              //   }
              // }],
              widgetLabels: [
                ...widget.chartData[0].data.map((s) => s?.timeCode ? s?.key?.slice(0, 10) : s?.key),
              ],
              // widgetLabels:['1','2'],
              widgetColors: [
                ...widget.chartData.map((e) => ({ backgroundColor: e.color, borderColor: "transparent" }))
              ],
            };
          case charts.PIE:
            return {
              widgetConfig: { ...this.defaultPieChartOptions },
              widgetData: [
                ...widget.chartData.map((s) =>
                  s.data.reduce((a, b) => a + b.value, 0)
                ),
              ],
              widgetLabels: widget.chartData.map((s) => s.label),
              widgetColors: [
                {
                  backgroundColor: widget.chartData.map((s) => s.color),
                  borderColor: "transparent",
                },
              ],
              widgetLegends: widget.chartData.map((s) => ({
                label: s.label,
                color: s.color,
                percentageVal: (
                  (s.data.reduce((a, b) => a + b.value, 0) * 100) /
                  [
                    ...widget.chartData.map((s) =>
                      s.data.reduce((a, b) => a + b.value, 0)
                    ),
                  ].reduce((a, b) => a + b, 0)
                ).toFixed(2),
              })),
            };
          case charts.DOUGHNUT:
            return {
              widgetConfig: { ...this.defaultDoughnutChartOptions },
              widgetData: [
                ...widget.chartData.map((s) =>
                  s.data.reduce((a, b) => a + b.value, 0)
                ),
              ],
              widgetLabels: widget.chartData.map((s) => s.label),
              widgetColors: [
                {
                  backgroundColor: widget.chartData.map((s) => s.color),
                  borderColor: "transparent",
                },
              ],
              widgetLegends: widget.chartData.map((s) => ({
                label: s.label,
                color: s.color,
                percentageVal: (
                  (s.data.reduce((a, b) => a + b.value, 0) * 100) /
                    [
                      ...widget.chartData.map((s) =>
                        s.data.reduce((a, b) => a + b.value, 0)
                      ),
                    ].reduce((a, b) => a + b, 0)
                ).toFixed(2),
              })),
            };
  
          default:
            break;
        }
      }
    };
    console.log(w)
    return {
      ...w,
      ...getWidgetConfig(w.apiData),
      intervalOptions:this.filterIntervals(w.intervals).map((e) =>
        intervalOptions.find((i) => i.key === e)
      ),
    };
  }

  async handleIntervalChange(widget) {

    const id = widget.id;
    // console.log(99,this.widgetsData,id)
    if(this.pageId){ // this is the condition check whether the pageId is there or not
      var res: any = await this.appService
        .getWidgetDataByPageId(
          id,
          this.timeDurationSelected,
          widget.selectedInterval,
          this.pageId
        )
        .toPromise();
    }
    else if(this.policyId && this.datasetId){ // this is the condition check whether the policyId is there or not
      var res: any = await this.appService.getWidgetDataByPolicyId(
        id,
        this.timeDurationSelected,
        widget.selectedInterval,
        this.policyId,
        this.datasetId
      ).toPromise();
    }
    else{
      var res: any = await this.appService
        .getWidgetDataById(
          id,
          this.timeDurationSelected,
          widget.selectedInterval
        )
        .toPromise();
    }
    const index = this.widgetsData.findIndex(e=>e.id ===widget.id)
    this.widgetsData[index] = this.getFormattedDataForSingleWidget({
      ...res.data,
      apiData: res.data,
      selectedInterval: widget.selectedInterval,
    });
    this.chart.update()
  }
  widgetId(i,item){
    return item.id
  }
  async handleEntityChange(e) {
    if (e.value) {
      this._data.changeLoaderVisibility(true);
      const res: any = await this.appService
        .getAttributeListByEntityName(e.value)
        .toPromise();
      this._data.changeLoaderVisibility(false);
      this.attributeOptions = res.data;
      this.widgetForm.patchValue({ attribute: null });
      switch (e.value) {
        case 'customer':
          this.dateAttributeOptions = this.customerDateOptions
          break;
        case 'transaction':
          this.dateAttributeOptions = this.txnDateOptions
          break;
        default:
          this.dateAttributeOptions = []
          break;
      }
    }
  }
  handleApplyFilterChange(e) {
    if (e.target.checked) {
      this.selectedIntervals = intervalOptions.map((e) => ({
        ...e,
        active: true,
      }));
    } else {
      this.selectedIntervals = [];
    }
  }
  removeFilter(i) {
    this.selectedIntervals.splice(i, 1);
  }
  handleAddLabel(label, i) {
    this.selectedChartData.apiData.includedLabelConfig.push(label);
    this.selectedChartData.apiData.excludedLabelConfig.splice(i, 1);
  }
  handleRemoveLabel(label, i) {
    this.selectedChartData.excludedLabelConfig.push(label);
    this.selectedChartData.includedLabelConfig.splice(i, 1);
  }
  async saveChartData(id, modal) {
    const payload = {
      ...this.widgetForm.value,
      id:this.selectedChartData.id,
      intervals: this.selectedIntervals.map((e) => e.key),
      includedLabelConfig: this.selectedChartData.apiData.includedLabelConfig,
      excludedLabelConfig: this.selectedChartData.apiData.excludedLabelConfig,
      insightsPages: this.selectedChartData.insightsPages,
      fieldType: this.selectedChartData.fieldType,
      subEntityName: this.selectedChartData.subEntityName,
    };
    const updatedRes: any = await this.appService.updateWidget(payload).toPromise();
    const updatedData = updatedRes.data
    this._data.changeLoaderVisibility(true)
    modal.close()
    this._data.changeLoaderVisibility(false)
    const index= this.widgetsData.findIndex((e) => e.id === id);
    if (updatedRes.data) {
      this._data.changeLoaderVisibility(true)
      const res: any = await this.getChartDataById(updatedData.id,updatedData.intervals && updatedData.intervals[0] ? updatedData.intervals[0] : null);
      this._data.changeLoaderVisibility(false)
      if (res) {
        this.widgetsData[index] = this.getFormattedDataForSingleWidget({
          ...res,
          apiData: res,
          selectedInterval: this.filterIntervals(this.widgetsData[index].intervals)[0],
        });
        this.chart.update();
      }
    }
  }
  async handleSaveCustomiseVisibility() {
    const payload =  {widgetList: this.widgetArrForVisibility.map(({id,insightsPages,visible})=>({
      widgetId: id,
      pageEnum: insightsPages.insightsPageEnum,
      pageStatus: visible ? "ACTIVE" : "INACTIVE"}))}
    this.modal.dismissAll()
    const temp: any = await this.appService.updateWidgetsVisibility(payload).toPromise();
    this._data.changeLoaderVisibility(false)
    this.getAllInsights(this.pageName)
     
  }
  selectChange() {
    this.getAllInsights(this.pageName);
  }
  getDefaultInterval() {
    switch (this.timeDurationSelected) {
      case this.timeDurationOptions[0].value:
        return "1M";
      case this.timeDurationOptions[1].value:
        return "1M";
      case this.timeDurationOptions[2].value:
        return "1d";
      case this.timeDurationOptions[3].value:
        return "1d";
      default:
        return "1d";
    }
  }
  setWidgetToDelete(id){
    this.widgetIdToDelete = id
  }
  handleCancelDeleteWidget(){
    this.widgetIdToDelete = null
  }
  handleDeleteWidget(modal){
    this._data.changeLoaderVisibility(true)
    this.appService.deleteWidget(this.widgetIdToDelete).subscribe(res=>{
      this._data.changeLoaderVisibility(false)
      const i = this.widgetsData.findIndex(e=>e.id===this.widgetIdToDelete)
      const j = this.widgetArrForVisibility.findIndex(e=>e.id===this.widgetIdToDelete)
      this.widgetsData.splice(i,1)
      this.widgetArrForVisibility.splice(j,1)
      this.widgetIdToDelete = null
      modal.close()
      this.chart.update()
    },err=>{
      this._data.changeLoaderVisibility(false)
      console.error(err)
    })
  }
  openDialog(content) {
    this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }
  openDialogSm(content) {
    this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "sm",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }
  openDialogMd(content) {
    this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }
  openDialogXl(content) {
    this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }

  closeInfo(event: MouseEvent){
    event.preventDefault();
    this.closeWidget.emit('close-widget')
  }

}
