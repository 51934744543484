import { Component, Input, OnInit } from '@angular/core';
import { AppService, DataService } from '../../../../app/services';

@Component({
  selector: 'app-customer-entity-details',
  templateUrl: './customer-entity-details.component.html',
  styleUrls: ['./customer-entity-details.component.scss','./../customer-detail.component.scss']
})
export class CustomerEntityDetailsComponent implements OnInit {
  @Input() customerDetails;

  constructor(private dataService: DataService,private _app: AppService) { }

  ngOnInit(): void {
  }

  copyToClipboard = async (text: string, notification?: boolean, notificationMesg?: string) => {
    await navigator.clipboard.writeText(text)
    if (notification) {
      this.dataService.toastrShow((notificationMesg || "Copied"),"success")
    }
  
  }

}
