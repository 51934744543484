import { Injectable } from '@angular/core';
import { Router , CanActivate , ActivatedRouteSnapshot , RouterStateSnapshot } from '@angular/router';
import { Observable , of } from "rxjs";
// import { of} from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { PaymentService } from '../services/payment.service';
import { AppService } from '../services/app.service';
import { Subscription } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {
  subscriptions : Subscription[] = [];

  constructor(
        private _router: Router,
        private data   : DataService,
        private pay : PaymentService,
        private app : AppService,
  ) {}
    
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):Observable<boolean>{
    console.log('Router Url' , route.routeConfig);
    let token = this.data.getCookiesByName('token');
    this.data.changeToken(token);
    if(token){
      console.log("abcd",route.routeConfig.path)
      return of(true);
      //return this.getSubscription(route.routeConfig.path);
    } else {
      // this._router.navigate(['/login']);
      console.log("efgh",state.url)
      this._router.navigate(['/login'] , { queryParams: { returnURL:state.url } });
      return of(false);
    }
  }
  getSubscription(url){
    return new Observable<boolean>(subsc => {
      var subscriber = subsc;
      var licence;
      licence = this.data.getCookiesByName('licence');
      this.data.changeToken(licence);
      if(licence){
        licence = JSON.parse(licence);
        console.log('From cookie Gaurds -> clientData : ' ,licence,url);
        if(licence){
          this.getClientData(subscriber,url)
        }else{
          this.isSubscribed(subscriber,url);
        }
      }else {
        this.unsubscribeAll();
        console.log('get SUbscription Gaurds -> clientData : ' , licence);
                
      }
    });
  }
  isSubscribed(subscriber,url){
    console.log('Is Subscribed');
    if(url == 'pricing'){
      subscriber.next(true);
    }else{
      this._router.navigate(['pricing']);
      subscriber.next(false);
    }
  }
  getClientData(subsc,url){
    var subscriber = subsc;
    var clientData;
    var a = this.data.loadClientData.subscribe( data => {
      clientData = data;
      if(clientData){
        console.log('Gaurds -> clientData : ' ,clientData,url);
        if(clientData.clientVerificationForPayment){
          if(url == 'verify'){
            this._router.navigate(['home']);
            subscriber.next(false);
          }else{
            subscriber.next(true);
          }
        }else{
          this.VerifyPage(subscriber,url);
        }
      }else {
        console.log('get Client Data Gaurds -> clientData : ' , clientData);
        this.app.getClientProfileData()
          .subscribe(
            res => {
              // let con = {
              //     clientVerificationForPayment : res.data.clientVerificationForPayment
              // };
              this.data.changeClientData(res.data);
              this.VerifyPage(subscriber, res.data.clientVerificationForPayment ? 'settings' : url);
            },
            err => {
              subscriber.next(false);
            }
          );
      }
    });
    this.subscriptions.push(a);
  }
  VerifyPage(subscriber,url){
    console.log('Verify Page : ', url);
    if(url == 'pricing' || url == 'invoices' || url == 'settings'){
      subscriber.next(true);
    }else{
      this._router.navigate(['verify']);
      subscriber.next(false);
    }
  }
  unsubscribeAll(){
    this.subscriptions.map(s => {
      s.unsubscribe();
    })
  }
}