import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  RedirectTo,
  RedirectToContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-redirect-to',
  template: RedirectTo.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectToComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = RedirectTo.height;
  @Input() public viewBox: string = getSVGViewBox(RedirectTo.viewBox);
  @Input() public width: number | string = RedirectTo.width;
  @Input()
  public set context(ctx: RedirectToContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: RedirectToContext = RedirectTo.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = RedirectToComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : RedirectTo.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
