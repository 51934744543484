import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uiName'
})
export class UiNamePipe implements PipeTransform {

  nameMap : any = {
    "creditSpentAddressDoc" : "Credit Spent On Address Check" ,
    "creditSpentAml"        : "Credit Spent On AML Check" ,
    "creditSpentIdentityDoc": "Credit Spent On Identity Check" ,
    "creditSpentLiveness"   : "Credit Spent On Liveness Check" ,
    "creditSpentSelfie"     : "Credit Spent On Selfie Check" ,
    "creditSpentTotal"      : "Total Credit Spent" ,
		
    "crimeCheck"            : "Crime",
    "pepCheck"              : "PEP",
    "sanctionCheck"         : "Sanction" ,
    "media"                 : "Adverse Media" ,
		
    "dob"                   : "Date Of Birth" ,
    "individualUserType"    : "User Type" ,
    "firstName"             : "First Name" ,
    "lastName"              : "Last Name" ,
    "middleName"            : "Middle Name" ,
		
    "residenceCountry"      : "Residence Country" ,
		
    "left_face"             : "Left Face" ,
    "right_face"            : "Right Face" ,
    "eye_blink"             : "Eye Blink" ,
		
    "REQUIRED_CONFIRMATION" : "REQUIRED CONFIRMATION" ,
    "CREATED"               : "PENDING" ,
		
    "STANDARD_SUBSCRIPTION" : "STANDARD SUBSCRIPTION",
    "PAYASGO"               : "PAY AS YOU GO" ,
		
    "id-checks"             : "Identity Check" ,
    "address-checks"        : "Address Checks"  ,
    "aml-checks"            : "AML Checks"  ,
		
    "ALL"                   : "All Checks"  ,
    "CORPORATE"             : "Business Verification"  ,
    "AML"                   : "AML Search"  ,
    "IDV"                   : "KYC Checks"  ,
    "payAsYouGo"            : "Pay As You Go"


  };

  transform(value: any, args?: any): any {
    if(this.nameMap.hasOwnProperty(value)){
      return this.nameMap[value];
    }
    else{
      return value;
    }
  }

}
