export class TourModel{
  title             : string ;
  content           : string ;
  next              : string ;
  previous          : string ;
  tourID            : any ;
  elemID            : any ;
  orientation       : any ;
  showNextButton    : boolean ;
  showPreviousButton: boolean ;
}


import { Injectable } from '@angular/core';
import { TourStructure } from '../utils/tour' ;

@Injectable({
  providedIn: 'root'
})

export class TourService {

  tourStructure : any[] = TourStructure;

  constructor(){
  }

  isTutorialPresent(route){
    let isPresent = this.tourStructure.filter(obj=>obj.route == route) ;
    if(isPresent.length){
      return true;
    }
    else{
      return false;
    }
  }

  hasSeenTutorial(route){
    let hasSeen = this.tourStructure.filter(obj=>obj.hasSeenTutorial == true) ;
    if(hasSeen.length){
      return true;
    }
    else{
      return false;
    }
  }

  getTourData(route){
    let isPresent = this.tourStructure.filter(obj=>obj.route == route) ;
    if(isPresent.length){
      return isPresent[0]
    }
    else{
      return null;
    }
  }
}
