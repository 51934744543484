import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-risk-screening-table',
  templateUrl: './risk-screening-table.component.html',
  styleUrls: ['./risk-screening-table.component.scss']
})
export class RiskScreeningTableComponent implements OnChanges {
  @Input() data;
  @Input() entityType;
  @Input() scrollId?;
  tags=[{"slug":"pep","name":"PEP","value":false,entity:"Individual"},{"slug":"crime","name":"Crime","value":false,entity:"Individual,Corporate,Organisation"},
  {"slug":"sanction","name":"Sanction","value":false,entity:"Individual,Corporate,Organisation"},{"slug":"watchlist","name":"Watchlist","value":false,entity:"Individual,Corporate,Organisation"},
  {"slug":"sip","name":"SIP","value":false,entity:"Individual"},{"slug":"sie","name":"SIE","value":false,entity:"Corporate,Organisation"}
  ]
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    let categoryList = [];
    if(changes['data']['currentValue']) this.data = changes['data']['currentValue'];
    if(this.data){
      for(let item of this?.data){
        categoryList.push(item.slug);
      }
      this.tags.map((m) => categoryList.includes(m.slug) ? m.value = true : m.value = false);
    }
  }

  goToTabs(){
    if(this.scrollId){
      const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
      const scrollToEl = document.getElementById(this.scrollId);
      const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
      let scrolly=topOfElement-250;
      window.scroll({ top: scrolly, behavior: "smooth" });
      // window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

}
