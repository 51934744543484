import { Inject, Injectable } from "@angular/core";
import {
  HttpClient
} from "@angular/common/http";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class LayoutService {
  layout:any=[];
  layoutMenu: any = [
    {
      slug:"home",
      title: "Dashboard",
      router: "/home",
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/home-i.svg", //"fa fa-home",
      iconActive: "/assets/images/tm-icon/home.svg",
      iconDarkActive: "/assets/images/tm-icon/home-d.svg",
      kycIconName:'home',
      children: []
    },
    {
      slug:"data-ingestion",
      title: "Data Integration",
      router: "/data-ingestion",
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/data-ingestion-i.svg",
      iconActive: "/assets/images/tm-icon/data-ingestion.svg",
      iconDarkActive: "/assets/images/tm-icon/data-ingestion-d.svg",
      kycIconName:'data-ingestion',
      children: []
    },
    {
      slug:"customer",
      title: "Customer Dashboard",
      router: "/customers",
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/customer-i.svg",
      iconActive: "/assets/images/tm-icon/customer.svg",
      iconDarkActive: "/assets/images/tm-icon/customer-d.svg",
      kycIconName:'customer',
      children: [],
    },
    {
      slug:"policy-configuration",
      title: "Risk Engine Setup",
      router: null,
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/rules-i.svg",
      iconActive: "/assets/images/tm-icon/rules.svg",
      iconDarkActive: "/assets/images/tm-icon/rules-d.svg",
      kycIconName:'rules',
      children: [
        {
          router: 'policy-configuration',
          title: 'Risk Engine Setup'
        },
        {
          router: 'lookup-table',
          title: 'Decision Table'
        },
        {
          router: 'risk-templates',
          title: 'Risk Templates'
        }
      ]
    },
    {
      slug:"alerts",
      title: "Alert & Remediation",
      router: null,
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/alert-i.svg",
      iconActive: "/assets/images/tm-icon/alert.svg",
      iconDarkActive: "/assets/images/tm-icon/alert-d.svg",
      kycIconName:'alert',
      children: [
        {
          router: 'alerts',
          title: 'Alert & Remediation'
        },
        {
          router: 'rules',
          title: 'Alert Configuration'
        }
      ]
    },
    // {
    //   slug:"rules",
    //   title: "Alert Configuration",
    //   router: "/rules",
    //   count: 0,
    //   role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
    //   customer:['india','international'],
    //   icon: "/assets/images/tm-icon/rules-i.svg",
    //   iconActive: "/assets/images/tm-icon/rules.svg",
    //   iconDarkActive: "/assets/images/tm-icon/rules-d.svg",
    //   kycIconName:'rules',
    //   children: []
    // },
    // {
    //   slug:"your-data",
    //   title: "Your Data",
    //   router: "/your-data",
    //   count: 0,
    //   role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
    //   customer:['india','international'],
    //   icon: "/assets/images/tm-icon/your-data-i.svg",
    //   iconActive: "/assets/images/tm-icon/your-data.svg",
    //   iconDarkActive: "/assets/images/tm-icon/your-data-d.svg",
    //   kycIconName:'your-data',
    //   children: [
    //   ]
    // },

    // {
    //   slug:"lookup-table",
    //   title: "Decision Table",
    //   router: "/lookup-table",
    //   count: 0,
    //   role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
    //   customer:['india','international'],
    //   icon: "/assets/images/tm-icon/your-data-i.svg",
    //   iconActive: "/assets/images/tm-icon/your-data.svg",
    //   iconDarkActive: "/assets/images/tm-icon/your-data-d.svg",
    //   kycIconName:'your-data',
    //   children: [
    //   ]
    // },
    {
      slug:"impact-analysis",
      title: "Impact Analysis",
      router: null,
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/resolution-i.svg",
      iconActive: "/assets/images/tm-icon/resolution.svg",
      iconDarkActive: "/assets/images/tm-icon/resolution-d.svg",
      kycIconName:'resolution',
      children: [
        {
          router: 'impact-analysis-configuration',
          title: 'Risk Engine Setup'
        },
        {
          router: 'impact-analysis-rule',
          title: 'Alert Configuration'
        }
      ]
    },
    {
      slug:"entity-resolution",
      title: "Entity Resolution",
      router: "/entity-resolution",
      count: 0,
      role: ["admin","ROLE_SUPERADMIN","ROLE_DEVOPS"],
      customer:['india','international'],
      icon: "/assets/images/tm-icon/data-ingestion-i.svg",
      iconActive: "/assets/images/tm-icon/data-ingestion.svg",
      iconDarkActive: "/assets/images/tm-icon/data-ingestion-d.svg",
      kycIconName:'transaction',
      children: []
    },
    {
      slug:"settings",
      title: "Settings",
      router: "settings/",
      count: 2,
      role: ["admin","ROLE_SUPERADMIN"],
      customer:['international'],
      icon: "/assets/images/tm-icon/settings-i.svg",
      iconActive: "/assets/images/tm-icon/settings.svg",
      iconDarkActive: "/assets/images/tm-icon/settings-d.svg",
      kycIconName:'settings',
      children:[]
      // children: [
      //   {
      //     title: "User",
      //     count: 0,
      //     router: "/settings/user"
      //   },
      //   {
      //     title: "Role",
      //     count: 0,
      //     router: "/settings/role"
      //   },
      //   {
      //     title: "Policy",
      //     count: 0,
      //     router: "/settings/policy",
      //   }
      // ]
    },
  ];

  layoutMenuTiny: any = [
    "/home",
    "/user-reports",
    "/aml-reports",
    "check-status",
    "/history",
    "/live-check",
    "/doc-extraction",
    "/settings",
    "/doc-extraction",
    "/roles",
    "/developer",
    "/faq"
  ];

  constructor(
    private http: HttpClient,
    @Inject("API_URL") public API_URL: any
  ) {
    // this.layoutMenuTiny = this.layoutMenu.map((x)=>{
    //   return x.router;
    // });
  }
  getUserMenu(){
    return this.http.get(`${this.API_URL}/fe/clientsuser/all-pages`).pipe(map((res: any) => res.data));
  }

  getmen() {
  //     var menus = [];
  //     if(usermenu.length>0){
  //       usermenu.forEach(item => {
  //         let menu = this.layoutMenu.find(obj=>
  //           item.pageSlug == obj.slug
  //         )
  //         if(menu!=undefined && menu!=null){
  //           menus.push(menu);
  //         }
  //       });
  //     }
  //     else{
  //       menus=this.layoutMenu;
  //     }
  //     return menus.filter((layout: any) => {
  //       return layout.role.includes(role);
  //     });
    console.log("the return ", this.layoutMenu)
    for (let i=0 ; i<this.layoutMenu.length ; i++){
      this.layout.push(this.layoutMenu[i])
      this.layout[i]["submenu"]=this.layoutMenu[i].children
    }
    console.log("the return ", this.layout)
    return this.layoutMenu;
  }
}
