import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "../services/app.service";

@Pipe({
  name: "CustomerDetailsPipe"
})
export class CustomerDetailsPipe implements PipeTransform {
    constructor(private _app: AppService){}

    async transform(value: string, group?: boolean) {
      const apiURL = group ? 'transacto/v1/cg/alert-count' : 'transacto/v1/customer/get-alerts-data';
        const res: any = await this._app.getCustomerAlertDetailsById(apiURL, value).toPromise();
        return group ? res?.data || '0' : res?.data;
    }
}
