import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
var temp = `
      <div class="text-center" style="max-width: 320px;margin: auto;">
        <div class="row justify-content-center align-items-center lead mb-2 pb-2" 
             style="font-weight: bolder;color: orange;border-bottom:1px solid;">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            &nbsp;WARNING
        </div>
        <div class="row justify-content-center">
          <p>
            You have unsaved changes.<br/> <strong> OK </strong> to lose these changes or 
            <br/> Press <strong> Cancel </strong> to go back and save these changes.
          </p>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="button-list" style="width:400px;">
            <button type="button" class="btn btn-success" (click)="decline()" >Cancel</button>
            <button type="button" class="btn btn-danger" (click)="confirm()" >OK</button>    
          </div>
        </div>
      </div>
  `;
@Component({
  selector: 'modal-content',
  template: `
      <div class="text-center" style="max-width: 320px;margin: auto;">
        <div class="row justify-content-center align-items-center lead mb-2 pb-2"
             style="font-weight: bolder;color: orange;border-bottom:1px solid;">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            &nbsp;WARNING
        </div>
        <div class="row justify-content-center">
          <p>
            You have unsaved changes. Please click on save button to save changes. <br/> <strong> OK </strong> to go back.
          </p>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="button-list" style="width:400px;">
            <button type="button" class="btn btn-success" (click)="decline()" >OK</button>
          </div>
        </div>
      </div>
  `
})
 
export class SavaDataComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];
  public messageSource = new BehaviorSubject<any>({});
  currentMessage = this.messageSource.asObservable();

  constructor(public modalRef: BsModalRef) {}
  confirm(): void {
    this.messageSource.next('confirm');
    this.modalRef.hide();
  }

  decline(): void {
    this.messageSource.next('cancel');
    this.modalRef.hide();
  }
  ngOnInit() {
    this.list.push('PROFIT!!!');
  }
}