export const TourStructure : any[] = [
  {
    "route"          : "/home" ,
    "tourID"         :  1 ,
    "hasSeenTutorial": false ,
    "tourData"       : [
      {
        "title"      : "Navigation" ,
        "content"    : "You can find all your navigation links here!" ,
        "elemID"     : 1 ,
        "orientation": "left"
      },
      {
        "title"      : "Credits" ,
        "content"    : "Here you can find all your purchased Subscription Credits.!" ,
        "elemID"     : "subscriptionsC" ,
        "orientation": "top",
        "position"	 : "slider"
      },
      {
        "title"      : "Total Checks" ,
        "content"    : "Here are the number of total checks performed by the client till date!" ,
        "elemID"     : "totalC" ,
        "orientation": "top",
        "position"	 : "slider"
      },
      {
        "title"      : "Standard Service" ,
        "content"    : "Here you can find all the standard services provided to the client till date." ,
        "elemID"     : "standardC" ,
        "orientation": "top",
        "position"	 : "slider"
      },
      {
        "title"      : "Status" ,
        "content"    : "Your Current Plan & Remaining Days." ,
        "elemID"     : "statusC" ,
        "orientation": "top",
        "position"	 : "slider"
      },
      {
        "title"      : "Notifications" ,
        "content"    : "Check for your AML Notifications Here. Always Stays Updated!" ,
        "elemID"     : 5 ,
        "orientation": "top"
      },
      {
        "title"      : "Profile" ,
        "content"    : "You can complete your profile , look for FAQs & Generate your API keys from here!" ,
        "elemID"     : 6 ,
        "orientation": "top"
      },
      // {
      // 	"title"      : "Credit History" ,
      // 	"content"    : "You can find your Credit/Purchase History here." ,
      // 	"elemID"     : "creditHistory" ,
      // 	"orientation": "top"
      // },
      // {
      // 	"title"      : "Check Status" ,
      // 	"content"    : "You can find your Credit/Purchase History here." ,
      // 	"elemID"     : "creditHistory" ,
      // 	"orientation": "bottom"
      // }
				
    ]
  },
  // {
  // 	"route"          : "/user-home" ,
  // 	"tourID"         :  2,
  // 	"hasSeenTutorial": false,
  // 	"tourData"       : [
  // 		{
  // 			"title"      : "Navigation" ,
  // 			"content"    : "You can find all your navigation links here!" ,
  // 			"elemID"     : 1 ,
  // 			"orientation": "left"
  // 		}
  // 	]
  // },
  // {
  // 	"route"          : "/user-reports/hhhhhhhhhh" ,
  // 	"tourID"         :  3,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/aml-alerts/hhhhhhhhhh" ,
  // 	"tourID"         :  4,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/live-check/hhhhhhhhhh" ,
  // 	"tourID"         :  5,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/general/hhhhhhhhhh" ,
  // 	"tourID"         :  6,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/theming/hhhhhhhhhh" ,
  // 	"tourID"         :  7,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/subscriptions" ,
  // 	"tourID"         :  8,
  // 	"hasSeenTutorial": false ,
  // 	"tourData"       : [
  // 		{
  // 			"title"      : "Credits" ,
  // 			"content"    : "Here you can find all your purchased Subscription Credits.!" ,
  // 			"elemID"     : "subscriptionsC" ,
  // 			"orientation": "top"
  // 		},
  // 		{
  // 			"title"      : "Total Checks" ,
  // 			"content"    : "Here are the number of total checks performed till date!" ,
  // 			"elemID"     : "totalC" ,
  // 			"orientation": "top"
  // 		},
  // 		{
  // 			"title"      : "Pay As You Go" ,
  // 			"content"    : "Here you can find all your purchased Pay As You Go Credits." ,
  // 			"elemID"     : "payasgoC" ,
  // 			"orientation": "left"
  // 		},
  // 		{
  // 			"title"      : "Status" ,
  // 			"content"    : "Your Current Plan & Remaining Days." ,
  // 			"elemID"     : "statusC" ,
  // 			"orientation": "top"
  // 		},
  // 		{
  // 			"title"      : "Credit History" ,
  // 			"content"    : "You can find your Credit/Purchase History here." ,
  // 			"elemID"     : "creditHistory" ,
  // 			"orientation": "bottom"
  // 		}
  // 	]
  // },
  // {
  // 	"route"          : "/roles/hhhhhhhhhh" ,
  // 	"tourID"         :  9,
  // 	"hasSeenTutorial": false
  // },
  // {
  // 	"route"          : "/pricing" ,
  // 	"tourID"         :  10,
  // 	"hasSeenTutorial": false ,
  // 	"tourData"       : [
  // 		{
  // 			"title"      : "Subscriptions" ,
  // 			"content"    : "You can buy any of our subscription Plan for Id , Address , AML Check!" ,
  // 			"elemID"     : "subscriptions" ,
  // 			"orientation": "top"
  // 		},
  // 		{
  // 			"title"      : "Pay As You Go" ,
  // 			"content"    : "Pay As You Go Plans can be used for IDV Checks. " ,
  // 			"elemID"     : "payasgo" ,
  // 			"orientation": "left"
  // 		}
  // 	]
  // }
];