import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { AuthService } from "../../../services/auth.service";
import { AppService } from "../../../services/app.service";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { first } from "rxjs/operators";
import { AccountRegister } from "../../../models/Auth";
import { Company } from "../../../models/Company";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  accountRegister: AccountRegister = new AccountRegister();
  countries: any;
  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{0}$";
  // urlPattern: any =
  //   "@^(http://|https://)?([a-z0-9][a-z0-9-]*.)+[a-z0-9][a-z0-9-]*$@i";
  onlyAlphabets: any = /^[^0-9 ]{1}[a-zA-Z ]*[a-zA-Z]+$/;

  submitted : any ;

  demoCheckbox : any ;
  marketingCheckbox : any ;
  invalidEmail: boolean = false;


  constructor(
    private _data: DataService,
    public router: Router,
    private _app: AppService,
    private _auth: AuthService
  ) {}

  ngOnInit() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
        },
        err => {
          console.log(err);
        }
      );
    console.log(this.accountRegister);
  }
  emaiisValid(){
    if(this.accountRegister['client'].email.includes("gmail")
    ||this.accountRegister['client'].email.includes("hotmail")
    || this.accountRegister['client'].email.includes("outlook")
    || this.accountRegister['client'].email.includes("yahoo")
    ||this.accountRegister['client'].email.includes("outree")
    ||this.accountRegister['client'].email.includes("yopmail")
    ||this.accountRegister['client'].email.includes("hush")
    ||this.accountRegister['client'].email.includes("yandex")
    
    )
    {
      this.invalidEmail=true;
    }
    else this.invalidEmail=false;
    console.log(this.invalidEmail);
  }
  submitChanges(a){
    // var form:any = document.getElementsByClassName("ng-invalid")[0].getElementsByClassName("ng-invalid")[0];
    var form:any ;
    if(document.getElementsByClassName("ng-invalid") &&  document.getElementsByClassName("ng-invalid")[0]){
      form = document.getElementsByClassName("ng-invalid")[0].getElementsByClassName("ng-invalid")[0];
    }
    if(!a) form.focus();
    return a;
  }
  doRegister() {
    console.log(this.accountRegister);

    this.accountRegister.client.clientType = "ORGANISATION";
    this.accountRegister['clientsUser'].email = this.accountRegister['client'].email ;
    this.accountRegister['clientsUser'].contactNumber =  this.accountRegister['client'].contactNumber ;

    this._data.changeLoaderVisibility(true);
    if(this.marketingCheckbox || this.demoCheckbox){
      this.accountRegister.sendContactUs = true ;
    }

    this._auth
      .doRegister(this.accountRegister)
      .pipe(first())
      .subscribe(
        res => {
          this._data.changeLoaderVisibility(false);
          console.log(res) ;
          if(this.marketingCheckbox || this.demoCheckbox){
            // this.sendContactMsg();
          }
          this._data.toastrShow(res["message"], "info"),
          this.router.navigate(["/login"]);
        },
        err => {
          this._data.changeLoaderVisibility(false);
          console.log(err);
        }
      );
  }
  
  addressCheck(check){
    return new RegExp(/[^-$@*()_:;"'/><!~+`]\w+/g).test(check);
  }

  sendContactMsg(){
    let country = this.countries.filter((obj)=>obj.countryCode == this.accountRegister['client'].countryCode );
    let countryName  ;
    if(country && country.length){
      countryName = country[0].countryName
    }
    let obj = {
      "companyName": this.accountRegister['client'].name ,
      "email"      : this.accountRegister['client'].email  ,
      "message"    :  this.accountRegister['client'].enquiryComments ,
      "name"       : this.accountRegister['clientsUser'].firstName  ,
      "phoneNumber": this.accountRegister['client'].contactNumber ,
      "country"    : countryName,
      "website"    :this.accountRegister['client'].website
    }
    console.log(obj);
    this._auth.sendContactMsg(obj)
      .subscribe(
        (res)=>{
          this._data.toastrShow(res["message"] , "info");
        }
      );
  }
}

//CNAME  , C-EMAIL , C-SITE , C-ID (O) , COUNTRY , TYPE , PASS (O)
