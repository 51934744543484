import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-relation-ship-details',
  templateUrl: './relation-ship-details.component.html',
  styleUrls: ['./relation-ship-details.component.scss']
})
export class RelationShipDetailsComponent implements OnInit,OnChanges {
  tableData;
  @Input() customerRelationData;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.customerRelationData.currentValue){
      this.customerRelationData = changes.customerRelationData.currentValue;
    }
    this.tableData = this.customerRelationData;
  }

  ngOnInit(): void {
    // this.tableData = data;
    // this.tableData = this.customerRelationData;
  }

}
