import {
  Add,
  Alert,
  Cross,
  Customer,
  DataIngestion,
  Download,
  DropdownArrow,
  Edit,
  Home,
  RedirectTo,
  Report,
  Resolution,
  Rules,
  SearchFilter,
  Settings,
  Sub,
  Transaction,
  ViewReport,
  YourData
} from '../assets';

export const assetsMap = {
  [Add.name]: Add,
  [Alert.name]: Alert,
  [Cross.name]: Cross,
  [Customer.name]: Customer,
  [DataIngestion.name]: DataIngestion,
  [Download.name]: Download,
  [DropdownArrow.name]: DropdownArrow,
  [Edit.name]: Edit,
  [Home.name]: Home,
  [RedirectTo.name]: RedirectTo,
  [Report.name]: Report,
  [Resolution.name]: Resolution,
  [Rules.name]: Rules,
  [SearchFilter.name]: SearchFilter,
  [Settings.name]: Settings,
  [Sub.name]: Sub,
  [Transaction.name]: Transaction,
  [ViewReport.name]: ViewReport,
  [YourData.name]: YourData
};
