import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from '../../../../app/services';
import { elementIds } from '../constants';

@Component({
  selector: 'app-aml-matches',
  templateUrl: './aml-matches.component.html',
  styleUrls: ['./aml-matches.component.scss']
})
export class AmlMatchesComponent implements OnInit,OnChanges {
  @Input() amlData;
  elementIds=elementIds;
  amlMatches: any;
  activeTab: any;
  filteredData: any;
  tabs: any[] = [
    {
      name: 'PEP',
      slug: 'pep',
      data: [],
    },
    {
      name: 'Sanction',
      slug: 'sanction',
      data: [],
    },
    {
      name: 'Crime',
      slug: 'crime',
      data: [],
    },
    {
      name: 'SIP',
      slug: 'sip',
      data: [],
    },
    {
      name: 'Watchlist',
      slug: 'watchlist',
      data: [],
    },
    {
      name: 'Adverse Media',
      slug: 'adverse-media',
      data: [],
    },
  ];
  obj={
    "HIGH":'danger-gradient',
    "MED":'warning-gradient',
    "LOW":'success-gradient'
  }

  constructor(private dataService: DataService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.amlData.currentValue){
      this.amlData = changes.amlData.currentValue;
    }
    if(this.amlData){
      this.getAmlData(this.amlData);
    }
  }

  ngOnInit(): void {
    this.activeTab = 'showAll';
    // this.amlMatches.forEach(eh => eh.active = true);
    // if(dumData.dum){
    //   this.getAmlData(dumData.dum);
    // }
  }

  renderFilters(){
    return this.tabs.filter(eh => eh.data.length > 0).length > 0
  }

  selectedTab(slug,index=null){
    console.log(index,slug)
    this.activeTab = slug
    if(slug === 'showAll'){
      this.filteredData = JSON.parse(JSON.stringify(this.tabs))
    }else{
      this.filteredData = this.tabs.filter(eh => eh.slug === this.activeTab)
    }
    console.log(this.filteredData)
  }

  getAmlData(response){
    if(response.length){
      const parseData = JSON.parse(response);
      if(parseData.data && parseData.data.content && parseData.data.content.length){
        if(parseData.data.content[0].details && parseData.data.content[0].details.length){
          for(let item of parseData.data.content[0].details){
            if(item.category && item.category.length){
              for(let cat of item.category){
                this.tabs.forEach((e) => {
                  if(e.slug == cat.slug){
                    e.risk="HIGH"
                    e.data.push(item)
                  }
                })
              }
            }
          }
          this.filteredData = JSON.parse(JSON.stringify(this.tabs));
        }
      }
      this.dataService.setCustomerAMLData(this.tabs);
    }
  }

  getAdverseData(response){
    if(response && response.content && response.content.length){
      this.tabs.forEach((e) => {
        if(e.slug == 'adverse-media'){
          e.data = response.content;
        }
      })
    }
  }

}
