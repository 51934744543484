import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "objArr"
})
export class ObjArrPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var arr = value ? Object.keys(value) : null;
    console.log("-------------------", arr);
    return arr;
  }
}
