import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { KycModalModule } from "../../shared/kyc-modal/kyc-modal.module" ;

import { ShowImageDirective } from './show-image.directive';

@NgModule({
  declarations: [ ShowImageDirective ],
  imports: [
    CommonModule
    // KycModalModule
  ],
  exports: [ ShowImageDirective ]
})
export class ShowImageModule { }
