import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ObjArrPipe } from "./obj-arr.pipe";
import { KeyValPipe } from "./key-val.pipe";
import { UiNamePipe } from './ui-name.pipe';
import { SafePipe } from "./url-safe.pipe";
import { CustomerDetailsPipe } from './customer-details.pipe';

@NgModule({
  declarations: [
  	ObjArrPipe,
  	KeyValPipe,
  	UiNamePipe,
    SafePipe,
    CustomerDetailsPipe
  ],
  imports: [
    CommonModule
  ],
  exports : [
  	ObjArrPipe,
  	KeyValPipe,
  	UiNamePipe,
    SafePipe,
    CustomerDetailsPipe
  ]
})
export class PipesModule { }
