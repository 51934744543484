export class Role{
  firstName 		 : string ;
  middleName		 : string ;
  lastName 		 : string ;
  email 			 : string ;
  // password		 : string ;
  designation 	 : string ;
  contactNumber	 : string ;
  role			?: roleAccess;
  image 			?:string ;

  cssClass 		?:  string ;
}
export class fuzziness {
  key: string;
  value: string;
}
export class roleAccess{
  "description": string ;
  "displayName": string ;
  "slug"       : string ;
}

// To sort any Array By attribute

export const sortAnArray = function sortArray(array, attributeValue){
  // array : The whole array which have to be sorted
  // attributeValue : The value by which we've to sort the array
  if(array.length) {
    return array.sort( (p, p2) => {
      const name1 = p[attributeValue].toUpperCase();
      const name2 = p2[attributeValue].toUpperCase();
      let comparison = 0;
      if (name1 > name2) {
          comparison = 1;
      } else if (name1 < name2) {
          comparison = -1;
      }
      return comparison;
    });
  }
  else{
    return [];
  }
}