import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-complete',
  templateUrl: './status-complete.component.html',
  styleUrls: ['./status-complete.component.scss']
})
export class StatusCompleteComponent implements OnInit {
  @Input() name;
  @Input() button;
  @Input() url;

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  goback(){
    this.route.navigateByUrl(`${this.url}`);
  }

}
