import { Directive, ElementRef, Input } from '@angular/core';
import { DataService } from '../../services/data.service';

@Directive({
  selector: '[appStatusHighlight]'
})
export class StatusHighlightDirective {
  @Input() status = '';
  statusObject: any = {
    green: [
      'DATA_INGESTION_COMPLETED',
      'Low',
      'COMPLETED',
      'LOW',
      'low',
      'successful',
      'Success',
      'CONFIRMED',
      'ACTIVE',
      'L3'
    ],
    red: [
      'HIGH',
      'High',
      'FAILED',
      'VERY HIGH',
      'EXPIRED',
      'L1'
    ]
  }
  theme=localStorage.getItem('currentTheme');
  constructor(private eleRef: ElementRef,private _data:DataService) { }

  ngOnInit() {
    this.highlightStatus();
    this._data.currentTheme.subscribe(res => {
      if (res) {
        this.theme = res
      }
    }
    )
  }
  highlightStatus() {
    if(this.statusObject.green.find(f => f.toLowerCase() === ((this.status || '').toLowerCase()))){
      this.eleRef.nativeElement.style.color = '#17B18C';
      this.eleRef.nativeElement.style.fontWeight = '500';
    }
    else if(this.statusObject.red.find(f => f.toLowerCase() === ((this.status || '').toLowerCase()))){
      this.eleRef.nativeElement.style.color = '#D84040';
      this.eleRef.nativeElement.style.fontWeight = '500';
    }
    else if(!this.status){
      this.eleRef.nativeElement.style.color = this.theme === 'theme-dark' ? '#f2f2f2' : '#6a6a6a';
      this.eleRef.nativeElement.style.fontWeight = '500';
    }
    else{
      this.eleRef.nativeElement.style.color = '#ED7D15';
      this.eleRef.nativeElement.style.fontWeight = '500';
    }
  }

}
