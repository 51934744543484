import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl,NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function emailValidator(password: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | any => {
    let isValidEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{0}$').test(control.value);
    let emailArray,domainArray;
    if(control.value){
      emailArray = control.value.split("@");
      domainArray = control.value.split(".");
      // object.reduce -- reduces to one count which traverse through all value and assign value to each created object in count
      // if duplicates then object.assign -- assign only to previous object in count
      const count = domainArray =>
        domainArray.reduce((a, b) =>
          Object.assign(a, {[b]: (a[b] || 0) + 1}), {})
      // takes each keys and filters array which satisfy condition : count > 1
      const duplicates = dict =>
        Object.keys(dict).filter((a) => dict[a] > 1)

      // console.log(count(domainArray)) // { Mike: 1, Matt: 1, Nancy: 2, Adam: 1, Jenny: 1, Carl: 1 }
      // console.log(duplicates(count(domainArray))) // [ 'Nancy' ]

      // should be like asas@asas.com => not like asas@asas && asasa@.com
      isValidEmail = isValidEmail && emailArray[1].includes(".") && !(emailArray[1].startsWith("."));
      // issues like 1212121@121212
      isValidEmail = isValidEmail && !(emailArray[0].search(/[a-zA-Z]/i) < 0);
      // issues like .com.com.com duplicates
      isValidEmail = isValidEmail && !(duplicates(count(domainArray)).length > 0);
    }
    return isValidEmail ? null : '_blank';
  };
}
@Directive({
  selector: '[emailValid]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true}]
})
export class EmailDirective implements Validator {
  emailValue: boolean;
  
  validate(control: FormControl): any{
    if(control.value)
      return emailValidator(this.emailValue)(control);
  }
}

