import { Component, OnInit , Input } from '@angular/core';
import { Observable,Observer } from "rxjs";
@Component({
  selector: 'app-aml-loader',
  templateUrl: './aml-loader.component.html',
  styleUrls: ['./aml-loader.component.scss']
})
export class AmlLoaderComponent implements OnInit {
  public loadingData :any = new Observable<string>((observer: Observer<any>) => {
    var data = {
      percent : 0,
      files : null
    }
    var count = 10;
    var interval = setInterval(() => {
      data.percent = count;
      data.files = (data.percent >= 100) ? '':this.progressChecksData[data.percent%15];
      observer.next(data);
      count+=(Math.floor(Math.random() * 4) + 1);
      if(data.percent >= 100){
        clearInterval(interval);
      }
    },80);
  });
  public progressChecksData:string[] = [
    "World Presidents Database",
    "CIA World Leaders",
    "Every Politician",
    "EU Members of Parliament",
    "CoE Parliamentary Assembly",
    "GB Insolvency Disqualified Directors",
    "INTERPOL Red Notices",
    "UN Consolidated Sanctions",
    "SDFM Blacklist",
    "Swiss SECO Sanctions/Embargoes",
    "OFAC Consolidated List",
    "US Denied Persons List",
    "GB Consolidated List of Targets",
    "EEAS Consolidated List",
    "Kyrgyz FIU National List" ,
    "UK - Police" ,
    "UK - HMT" ,
    "NYSE",
    "FBI"

  ];
	@Input() isAMLLoader   : boolean ;
	constructor() { }

	ngOnInit() {
	}

}
