export { Add, AddContext } from './add';
export { Alert, AlertContext } from './alert';
export { Cross, CrossContext } from './cross';
export { Customer, CustomerContext } from './customer';
export { DataIngestion, DataIngestionContext } from './data-ingestion';
export { Download, DownloadContext } from './download';
export { DropdownArrow, DropdownArrowContext } from './dropdownArrow';
export { Edit, EditContext } from './edit';
export { Home, HomeContext } from './home';
export { RedirectTo, RedirectToContext } from './redirect-to';
export { Report, ReportContext } from './report';
export { Resolution, ResolutionContext } from './resolution';
export { Rules, RulesContext } from './rules';
export { SearchFilter, SearchFilterContext } from './searchFilter';
export { Settings, SettingsContext } from './settings';
export { Sub, SubContext } from './sub';
export { Transaction, TransactionContext } from './transaction';
export { ViewReport, ViewReportContext } from './view-report';
export { YourData, YourDataContext } from './your-data';export type KycIconAsset = 'add'|'alert'|'cross'|'customer'|'data-ingestion'|'download'|'dropdownArrow'|'edit'|'home'|'redirect-to'|'report'|'resolution'|'rules'|'searchFilter'|'settings'|'sub'|'transaction'|'view-report'|'your-data';
    export function getSVGViewBox(viewBox: any): string {
      return [viewBox.minx, viewBox.miny, viewBox.width, viewBox.height].join(' ');
    }
  