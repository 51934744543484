import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-unavailable',
  templateUrl: './data-unavailable.component.html',
  styleUrls: ['./data-unavailable.component.scss']
})
export class DataUnavailableComponent implements OnInit {
  imageUrl="../../../assets/images/icons/no-data-found/"
  emptyImageUrl="../../../assets/images/icons/no-data-found/searchFilter.svg"
  @Input() heading? : any = 'No Results Found';
  @Input() subheading? : any = '';
  @Input() isButton? : boolean = false;
  @Input() link? : any = 'home';
  @Input() name? : any = 'Go';
  @Input() image? : any = 'alert.svg';
  @Input() searchFilter?:boolean=false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.imageUrl=this.imageUrl+this.image;
  }
  goto(){

    this.router.navigate([`/${this.link}`]);
  }
}
