import { Injectable } from '@angular/core';
// import { Ng2DeviceService } from 'ng2-device-detector';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DataService } from "../services/data.service";
import { AppService } from './app.service';

declare var window;

@Injectable({
  providedIn: 'root'
})

export class TrackingService {
  ip: any;
  location: any;

  constructor(private _data: DataService, private _app: AppService, private deviceService: DeviceDetectorService) {
    // this._app.getSystemIp().subscribe((res: any) => {
    // 	this.ip = res.ip;
    // 	console.log("ip adress", this.ip)
    // });
    this.getLocation();
  }

  initiateIntercom() {
    // var clientData = this._data.getCookiesByName("clientData");
    // console.log("Intercom Initiated");
    // window.Intercom("update");
  }

  trackBasicData() {
    // var data = this._data.getCookiesByName("clientData");
    // var clientData = data ? JSON.parse(data) : null ;
    // if(clientData){
    // 	window.Intercom("boot", {
    // 		app_id    : "x0bx64oo",
    // 		name      : clientData["name"] , // Full name
    // 		email     : clientData["email"], // Email address
    // 		created_at: clientData["createdDate"] // Signup date
    // 	});
    // }
  }

  trackCustomData() {

  }

  activityLog() {
    const deviceInfo = this.detectDevice();
    console.log(deviceInfo)
    let logData = {
      os: deviceInfo.deviceInfo.os,
      browser: deviceInfo.deviceInfo.browser,
      device: deviceInfo.device,
      location: this.location,
      ip: this.ip
    }
    this._app.coActivityLog(logData).subscribe(res => {
      console.log(res);
    })
  }

  getLocation() {
    if (navigator.geolocation) {
      console.log("working....")
      navigator.geolocation.getCurrentPosition((position) => {
        this._app.getLocation(position.coords.latitude, position.coords.longitude).subscribe(res => {
          const { city, locality, countryName } = res;
          console.log(`working....<${city}, ${locality}, ${countryName}>`)
          if (city == "") {
            this.location = `${locality}, ${countryName}`;
          } else {
            this.location = `${city}, ${countryName}`;
          }
        });
      });
    } else {
      return null;
    }
  }

  detectDevice() {
    console.log('Detecting........');
    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    let deviceName
    if (this.deviceService.device == "unknown") {
      if (isDesktopDevice) {
        deviceName = 'Desktop';
      }
      if (isMobile) {
        deviceName = 'Mobile';
      }
      if (isTablet) {
        deviceName = 'Tablet';
      }
    } else deviceName = deviceInfo.device;
    const device = deviceName;
    const info = { deviceInfo, device }
    return info;
  }

}
