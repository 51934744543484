import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-interval-wrapper',
  templateUrl: './interval-wrapper.component.html',
  styleUrls: ['./interval-wrapper.component.scss','../widgets/widgets.component.scss']
})
export class IntervalWrapperComponent implements OnInit {
  @Input() widgetLegends;
  slideConfig = {
    slidesToShow:4,
    slidesToScroll: 1,
    vertical:true ,
    verticalSwiping:true,
    infinite:false,
    prevArrow: '<div class="slick-icon-container"><span class="icon" style="align-items:flex-start;"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-up fa-2x" aria-hidden="true"></i></span></div>',
    nextArrow: '<div class="slick-icon-container"><span class="icon" style="align-items:center"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-down fa-2x" aria-hidden="true"></i></span></div>',

  };
  constructor() { }
  legendID(i,item){
    return item.label
  }
  ngOnInit(): void {
  }

}
