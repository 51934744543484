import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrls: ['./nested-accordion.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0',opacity:'0',display:'none'})),
      state('expanded', style({height: '*',opacity:'1',zIndex:1,display:'block'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NestedAccordionComponent implements OnInit,OnChanges {
  @Input() nestedAccordion;
  expanded: {[key: string]: boolean} = {};
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.nestedAccordion.currentValue){
      this.nestedAccordion = changes.nestedAccordion.currentValue;
    }
  }

  ngOnInit(): void {
  }

  isRowClickable(rowIndex: number): boolean {
    return this.nestedAccordion[rowIndex].childs && this.nestedAccordion[rowIndex].childs.length > 0
  }

}
