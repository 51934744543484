import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adverse-media-articles',
  templateUrl: './adverse-media-articles.component.html',
  styleUrls: ['./adverse-media-articles.component.scss']
})
export class AdverseMediaArticlesComponent implements OnInit {
  @Input() articles;
  constructor() { }

  ngOnInit(): void { }

}
