export class ClientData {
  clientType: any;
  name: any;
  email: any;
  userName: any;
  addressLine1: any;
  addressLine2: any;
  city: any;
  state: any;
  postalCode: any;
  country: any = { countryName: null };
  countryCode: any;
  website: any;
  companyID: any;
  industryType: any;
  companyType: any;
  companySize: any;
  fbProfileUrl: any;
  twitterProfileUrl: any;
  linkedInProfileUrl: any;
  contactNumber: any;
  createdDate: any;
  lastModifiedDate: any;
}

export class ClientConfig {
  passStp: any;
  failStp: any;
  logoUrl: any;
  dashboardTheme: any;
  feAsTheme: any;
}

import { Component, OnInit, OnDestroy } from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import { Router, NavigationEnd } from "@angular/router";
import { DataService } from '../../services/data.service';
import { filter } from 'rxjs/operators';
import { AppService } from "../../services/app.service";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {

  hasSub: boolean[] = [];
  layoutMenus: any = [];
  currentUrl: any;

  bsModalRef: BsModalRef;
  isSideBarHidden: boolean;
  //client profile
  clientData: any = new ClientData();
  tempFlag: boolean = false;
  standardServices: any = null;

  subscriptions: Subscription[] = [];
  isChild: any = '';
  currentTheme: string = localStorage.getItem('currentTheme');
  childRouter: any;
  constructor(
    private _layoutService: LayoutService,
    private router: Router,
    private data: DataService,
    private modal: NgbModal,
    private appService: AppService

  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(e => {
        this.currentUrl = e["url"];
        this.hasSub = [];
        this.getSub(this.currentUrl);
      });
  }

  getSub(currentUrl) {
    let layoutMenu = this.layoutMenus;
    if (this.tempFlag == false) {
      var getItem;
      var getIndex;
      for (var i = 0; i < layoutMenu.length; ++i) {
        if (layoutMenu[i].children.length == 0 && layoutMenu[i].router == currentUrl) {
          getItem = layoutMenu[i];
          getIndex = [i];
          break;
        }
        else if (layoutMenu[i].children.length > 0) {
          layoutMenu[i].children.forEach(element => {
            if (element.router == currentUrl) {
              getItem = layoutMenu[i];
              getIndex = [i];
            }
          });
          if(getItem != undefined && getIndex != undefined){
            break;
          }
        }
      }
      this.hasSubChange(getIndex, getItem);
      this.tempFlag = true;
    }
    for (var i = 0; i < layoutMenu.length; ++i) {
      if (layoutMenu[i].router === currentUrl) {
        this.hasSub[i] = true;
      } else {
        for (var j = 0; j < layoutMenu[i].children.length; ++j) {
          if (layoutMenu[i].children[j].router === currentUrl) {
            this.hasSub[i] = true;
          }
        }
      }
    }
  }

  isRouteAccessible(item) {
    // return true;
    // if(item && !item["access_slug"]){
    //   return true;
    // }
    // else if(item && item["access_slug"] && this.standardServices){
    if (this.standardServices) {
      let isPresent = this.standardServices.filter(obj => obj.slugGroup == item["access_slug"]);
      var allowedServices = this.data.isSidebarServicesPresent(this.standardServices);

      // console.log(allowedServices.includes(this.getSlug(item["title"])));
      if (isPresent && isPresent.length && !allowedServices.includes(this.getSlug(item["title"]))) {
        return true;
      } else {
        return false;
      }
    }
    else return false;
  }
  // to get slug
  getSlug(data) {
    return data.toLowerCase().replace(/ /g, '-');
  }

  ngOnInit() {
    this.appService.currentTheme.subscribe(theme => {
      console.log('theme from sidebar',this.currentTheme)
      this.currentTheme = theme;
    })
    this.getLayout1();
    this.data.sideBarHidden.subscribe((val) => this.isSideBarHidden = val);
  }


  getLayout1() {
    this.layoutMenus = this._layoutService.getmen();
  }

  activeRoute(routename: string): boolean {
    return this.router.url == routename;
  }

  //click hndler for sub menu
  subMenuClick(item, e) {
    console.log(item.title);
    // var individualHeight = 38;
    if (item.children.length) {
      document.getElementById(item.title).classList.toggle("rotateDown");
      document.getElementById("dropdown" + e).classList.toggle("show");
      // document.getElementById("dropdown" + e).style.height = (document.getElementById("dropdown" + e).style.height.length) ? "0px" : (individualHeight * parseInt(item.children.length)) + "px";
    }
  }

  openLink(item, i) {
    if (item.children.length) {
      this.subMenuClick(item, i);
    }
    else {
      this.router.navigate([item.router]);
    }
  }

  activeClass(item) {
    // console.log("this.router.url"+this.router.url);
    // console.log("this.currentUrl"+this.currentUrl);
    var cond1 = this.currentUrl == item.router;
    var hasRouter;
    if (item.children.length) {
      var arr = item.children;
      var hasRouter = arr.filter((a) => {
        return a.router == this.currentUrl;
      });
    }
    if (hasRouter) {
      if (hasRouter.length) {
        return 'active'
      }
    }
    else if (cond1) {
      return 'active';
    }
    else {
      return null;
    }
  }

  hasSubChange(index: any, menu: object) {
    // if(document.querySelector('app-sidebar > div.side-menu')['offsetWidth'] !== 70){
    // console.log(menu);
    this.isChild = ''
    console.log('layour',)
    if (menu["router"] == "/entity-resolution") {
      window.open("https://datamanagment.opsflow.kychub.com", "_blank");
      return;
    }

    if (menu['children'].length > 0) {
      this.isChild = menu['title'];
      console.log(">>>>>>>>>>>>>>", this.isChild);

    }

    if (menu['router']) {
      this.router.navigate([menu['router']]);
      if (!this.hasSub[index]) {
        this.hasSub = [];
      }
      this.hasSub[index] = !this.hasSub[index];
    } else {
      if (!this.hasSub[index]) {
        this.hasSub = [];
      }
      this.hasSub[index] = !this.hasSub[index];
    }
    console.log('layour', menu, this.isChild, this.hasSub[index])
    // }
  }

  // logOut(){
  //   this.data.removeAllCookie();
  //   this.router.navigate(['/login'])
  // }

  openDialog(TemplateRef){
    this.modal.open(TemplateRef, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      backdrop: "static",
      modalDialogClass:
        this.currentTheme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }

  doLogout() {
    sessionStorage.clear();
    localStorage.clear();
    this.data.removeAllCookie();
    this.data.unsubscribeAll();
    this.router.navigate(['/login']);
  }

  hideSideBar() {
    var element = document.getElementById("wrapper");
    let sidebarLogo = document.getElementById('sidebar-logo');
    if (!this.isSideBarHidden) {
      //hide it
      element.classList.add("forced", "enlarged");
      this.isSideBarHidden = true;
      this.data.sideBarHiddenVal(this.isSideBarHidden);
      sidebarLogo.style.marginLeft = '-12px';
    } else {
      // unhide it
      element.classList.remove("forced", "enlarged");
      this.isSideBarHidden = false;
      this.data.sideBarHiddenVal(this.isSideBarHidden);
      sidebarLogo.style.marginLeft = 'unset';
    }
  }
}


