import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  Transaction,
  TransactionContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-transaction',
  template: Transaction.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = Transaction.height;
  @Input() public viewBox: string = getSVGViewBox(Transaction.viewBox);
  @Input() public width: number | string = Transaction.width;
  @Input()
  public set context(ctx: TransactionContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: TransactionContext = Transaction.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = TransactionComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : Transaction.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
