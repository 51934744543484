import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "../../../services/app.service";
import { DataService } from "../../../services/data.service";
import { charts, pages, entities, intervalOptions } from "../../../../assets/constants/insights-dummy";
import { sortAnArray } from "../../../models/Role.model";

@Component({
  selector: "app-create-widget",
  templateUrl: "./create-widget.component.html",
  styleUrls: ["./create-widget.component.scss", "../widgets/widgets.component.scss"],
})
export class CreateWidgetComponent implements OnInit {
  @Output() getAllInsights: EventEmitter<any> = new EventEmitter();
  public widgetForm: FormGroup;
  selectedIntervals: any = [];
  chartOptions: any = [];
  entityOptions: any = [];
  attributeOptions: any = [];
  txnDateOptions: any = [
    { key: "dateOfTransaction", label: 'Date of Transaction' },
    { key: "createdDate", label: "Created Date" },
    { key: "modifyDate", label: "Modified Date" }]
  customerDateOptions: any = [
    { key: "accountCreatedDate", label: 'Account Created Date' },
    { key: "createdDate", label: "Created Date" },
    { key: "modifyDate", label: "Modified Date" }]
  dateAttributeOptions: any = [];
  intervalOptions: any = intervalOptions;
  theme: any = 'theme-light'
  constructor(
    private modal: NgbModal,
    private form: FormBuilder,
    private appService: AppService,
    private _data: DataService,
  ) {
    this.widgetForm = this.form.group({
      name: this.form.control("", Validators.required),
      description: this.form.control("", Validators.required),
      chartType: this.form.control(null, Validators.required),
      entity: this.form.control(null, Validators.required),
      attribute: this.form.control(null, Validators.required),
      dateAttribute: this.form.control(null),
    });
    this.chartOptions = Object.keys(charts).map(e => ({ name: e, value: charts[e] }))
    this.entityOptions = Object.keys(entities).filter(f => entities[f] != 'rule').map(e => ({ name: e, value: entities[e] }))

  }
  fetchAllInsights
  ngOnInit(): void {
    this._data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    });
  }

  async handleEntityChange(e) {
    if (e.value) {
      this._data.changeLoaderVisibility(true)
      const res: any = await this.appService.getAttributeListByEntityName(e.value).toPromise()
      this._data.changeLoaderVisibility(false)
      this.attributeOptions = res.data;
      this.attributeOptions = sortAnArray(this.attributeOptions, 'attribute');
      this.widgetForm.patchValue({ attribute: null })
      switch (e.value) {
        case 'customer':
          this.dateAttributeOptions = this.customerDateOptions
          break;
        case 'transaction':
          this.dateAttributeOptions = this.txnDateOptions
          break;
        default:
          this.dateAttributeOptions = []
          break;
      }
    }
  }
  handleApplyFilterChange(e) {
    if (e.target.checked) {
      this.selectedIntervals = intervalOptions.map(e => ({ ...e, active: true })) || []
    }
    else {
      this.selectedIntervals = []
    }
  }
  async handleCreateWidget(modal) {
    const payload = {
      ...this.widgetForm.value,
      fieldType: this.attributeOptions.find(e => e.attribute === this.widgetForm.value.attribute).dataType,
      ...this.attributeOptions.find(e => e.attribute === this.widgetForm.value.attribute),
      intervals: this.selectedIntervals.map(e => e.key),
      insightsPages: [
        {
          insightsPageEnum: "HOME",
          status: "ACTIVE"
        },
        {
          insightsPageEnum: this.entityOptions.find(e => e.value === this.widgetForm.value.entity).name,
          status: "ACTIVE"
        }
      ],

    }
    this._data.changeLoaderVisibility(true)
    const res = await this.appService.createWidget(payload).toPromise()
    this._data.changeLoaderVisibility(false)
    this.modal.dismissAll()
    this.getAllInsights.emit()
  }

  removeFilter(i) {
    this.selectedIntervals.splice(i, 1)
  }
  resetForm() {
    this.widgetForm.reset()
    this.selectedIntervals = []
    this.attributeOptions = []
  }
  openDialog(content) {
    this.modal.open(content, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }
}
