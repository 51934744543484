import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddComponent,
  AlertComponent,
  CrossComponent,
  CustomerComponent,
  DataIngestionComponent,
  DownloadComponent,
  DropdownArrowComponent,
  EditComponent,
  HomeComponent,
  RedirectToComponent,
  ReportComponent,
  ResolutionComponent,
  RulesComponent,
  SearchFilterComponent,
  SettingsComponent,
  SubComponent,
  TransactionComponent,
  ViewReportComponent,
  YourDataComponent,
  KycIconComponent
} from './components'

const comps = [
  AddComponent,
  AlertComponent,
  CrossComponent,
  CustomerComponent,
  DataIngestionComponent,
  DownloadComponent,
  DropdownArrowComponent,
  EditComponent,
  HomeComponent,
  RedirectToComponent,
  ReportComponent,
  ResolutionComponent,
  RulesComponent,
  SearchFilterComponent,
  SettingsComponent,
  SubComponent,
  TransactionComponent,
  ViewReportComponent,
  YourDataComponent,
  KycIconComponent
];

@NgModule({
  declarations: [ ...comps ],
  exports: [ ...comps ],
  imports:[ CommonModule ],
  entryComponents: [ ...comps ]
})
export class KycIconModule { }
