import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient, @Inject('TM_API_URL') public TM_API_URL: any) { }

  getCustomerDetailByCustomerId(apiUrl){
    return this.http.get(`${this.TM_API_URL}/${apiUrl}`).pipe(map((res) => res['data']));
  }

  getTransactionDetailsByGuid(guid: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/transaction/search/${guid}`).pipe(map((res) => res['data']));
  }
  getRiskDetails(id){
    return this.http.get(`${this.TM_API_URL}/payment/screening/get/risk/${id}`).pipe(map((res) => res));
  }

  getAMLResults(name: string){
    return this.http.post(`${this.TM_API_URL}/aml/search`, {
      "size": 10,
      "page": 0,
      "searchType": "STRICT",
      "name": [
        name
      ],
      "country": [],
      "source": [],
      "guid": "",
      "category": [],
      "gender": [],
      "type": [
        "Individual"
      ]
    }).pipe(map((res: any) => res['data']['data']));
  }

  getCRRresults(guid: string){
    return this.http.get(`${this.TM_API_URL}/aml/risk-calculation/${guid}`).pipe(map((res) => res['data']));
  }

  getListOfTransactions(page: number, size: number){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/transaction/page/get`, {
      "pageNo": page,
      "size": size
    }).pipe(map((res: any) => res['data']));
  }

  getGeographywiseAlert(){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/transaction/visualize/country`).pipe(map((res) => res['data']));
  }

  getModeOfTransactionGraph(countryName){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/transaction/visualize/transaction-type/${countryName}`).pipe(map((res) => res['data']));
  }

  getAvgVolumeGraph(countryName){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/transaction/visualize/datewise-transaction/${countryName}`, {
      "startDate":"2021-07-01",
      "endDate":"2022-08-30"
    }).pipe(map((res) => res['data']));
  }

  getYourDataListByDatasetId(page: number, size: number, datasetId: number, type: string){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/${type}/system-data/${datasetId}`, {
      "page": page,
      "size": size
    }).pipe(map((res: any) => res['data']));
  }

  getCrrBreakPoints(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/crr-data/search`, payload).pipe(map((res) => res['data']));
  }

  getTransactionRiskDetails(transactionId){
    return this.http.get(`${this.TM_API_URL}/payment/screening/get/transaction-risk/${transactionId}`).pipe(map((res) => res['data']));
  }

  getAccountList(customerId: String, payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/account/get-by-customer?customerIdNumber=${customerId}`, payload).pipe(map((res) => res['data']));
  }

  getCustomerLogs(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/customer/customer-logs`, payload).pipe(map((res) => res['data']));
  }

  getAdverseArticles(payload: Object){
    return this.http.post(`${this.TM_API_URL}/aml/adverse-media-engine`, payload).pipe(map((res) => res['data']));
  }

  getRuleBreachListByTransactionId(payload: Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/get-by-transactionId`, payload).pipe(map((res) => res['data']));
  }

  getListOfAllRulesByTransactionId(payload: Object, type: string){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/user-alert/applied-violated/rules/${type}`, payload).pipe(map((res) => res['data']));
  }

  getDataIngestionList(payload:Object){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/ah-logs/dataset`, payload).pipe(map((res) => res));
  }

  getRuleListByCustomerId(payload: Object, type: string){
    return this.http.post(`${this.TM_API_URL}/transacto/v1/customer/applied-violated/rules/${type}`, payload).pipe(map((res) => res['data']));
  }

  pastAlertData(customerId: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/customer/past-alerts/suspicious-activity/${customerId}`).pipe(map((res) => res['data']));
  }

  trnsactionInvolvingPep(customerId: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/customer/involved-category/percentage/${customerId}`).pipe(map((res) => res['data']));
  }

  trnsactionOutsideCountry(customerId: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/customer/outside-transaction/percentage/${customerId}`).pipe(map((res) => res['data']));
  }

  trnsactionVelocityData(customerId: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/customer/transaction-velocity/risk/${customerId}`).pipe(map((res) => res['data']));
  }

  trnsactionCrossBorder(customerId: string){
    return this.http.get(`${this.TM_API_URL}/transacto/v1/customer/cross-border/transaction/${customerId}`).pipe(map((res) => res['data']));
  }

}
