import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl,NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function addressValidator(password: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | any => {
    let isAddress = new RegExp(/.*[a-zA-Z]{3,}(?=\w).*/m).test(control.value);
    return isAddress ? null : '_blank';
  };
}

@Directive({
  selector: '[addressValid]',
  providers: [{provide: NG_VALIDATORS, useExisting: AddressValidatorDirective, multi: true}]
})
export class AddressValidatorDirective implements Validator {
  addressValue: boolean;
  
  validate(control: FormControl): any{
    if(control.value)
      return addressValidator(this.addressValue)(control);
  }
}

