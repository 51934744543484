import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

import {
  Sub,
  SubContext,
  getSVGViewBox
} from '../assets';

@Component({
  selector: 'kyc-icon-sub',
  template: Sub.svg,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubComponent implements SVG2TSDynamic, OnInit {
  public static UUID = 0;
  public baseUrl: string;
  @Input() public height: number | string = Sub.height;
  @Input() public viewBox: string = getSVGViewBox(Sub.viewBox);
  @Input() public width: number | string = Sub.width;
  @Input()
  public set context(ctx: SubContext) {
    this.updateContext(ctx);
  }
  public get context() {
    return this._context;
  }

  private _context: SubContext = Sub.contextDefaults;

  constructor(private _ref: ChangeDetectorRef) {}


  public getURLBase(value: string) {
    return `url('${this.getXlinkBase(value)}')`;
  }

  public getXlinkBase(value: string) {
    return `${this.baseUrl}${value}`;
  }

  public ngOnInit() {
    this.baseUrl = window.location.href.replace(window.location.hash, '');
    this.context.uuid = SubComponent.UUID++;
  }

  public updateContext(ctx: any) {
    this._context = Object.assign({}, this._context ? this._context : Sub.contextDefaults, ctx);
    this._ref.markForCheck();
  }
}
