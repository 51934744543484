import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { ResolutionService } from '../../pages/resolution/services/resolution.service';
import { fromEvent } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService } from '../../../app/pages/alerts/services/alerts.service';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-alerts-report',
  templateUrl: './alerts-report.component.html',
  styleUrls: ['./alerts-report.component.scss'],
})

export class AlertsReportComponent implements OnInit {
  item: any;
  @Output() close = new EventEmitter<any>();
  history: any = [] = [];
  assigneList: any[] = []
  sticky: boolean = false;
  windowSize: number = 0;
  theme: any;
  disableAction: any[] = ['CLOSED'];
  actionsArray: any[] = [
    // { name: 'Raise SAR', slug: 'sar', icons: '/assets/images/icons/raise-sar.svg' },
    { name: 'Resolve', slug: 'resolve', icons: '/assets/images/icons/green-tick.svg' },
    { name: 'Contact Remitter', slug: 'remitter', icons: '/assets/images/icons/contact-remitter.svg' },
    { name: 'Download as PDF', slug: 'download', kycIcons: true, icons: 'download' },
  ];
  status = [
    { value: 'Open', key: 'OPEN' },
    { value: 'In Review', key: 'IN_REVIEW' },
    { value: 'Closed', key: 'CLOSED' }
  ];
  isDisabledButton: boolean = true;
  public activityForm: FormGroup;
  public statusForm: FormGroup;
  public assigneeForm: FormGroup;
  actionTakenArray: any[] = [];
  activityArray: any[] = [];
  riskBreakdownData: any[] = [];
  policyData: any;
  customerDetails: any;
  timeRemaining: number = 0;
  crrScore: number = 0;
  amlLabel: any = 'Low';
  saveAsCopy: any;
  assigneeName: any;
  historyPagination = {
    page: 1,
    total: 0,
    totalPage: 0,
    size: 5
  };

  constructor(
    private alertService: AlertsService,
    private modal: NgbModal,
    private data: DataService,
    private fb: FormBuilder,
    private service: ResolutionService) {
    this.statusForm = this.fb.group({
      status: this.fb.control('', Validators.required),
      description: this.fb.control('', Validators.required)
    }),
      this.assigneeForm = this.fb.group({
        assignee: this.fb.control('', Validators.required),
        description: this.fb.control('', Validators.required)
      });
    this.activityForm = this.fb.group({
      activity: this.fb.control('', Validators.required),
      actions: this.fb.control('', Validators.required)
    });
    this.windowSize = screen.width
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSize = event.target.innerWidth;
  }

  ngOnInit(): void {
    // Hide Sidebar
    this.getTheme();
    var element = document.getElementById("wrapper");
    let sidebarLogo = document.getElementById('sidebar-logo');
    let hideLogo = document.getElementById('hide-logo');
    element.classList.add("forced", "enlarged");
    if (sidebarLogo) sidebarLogo.style.marginLeft = '-12px';
    if (hideLogo) {
      hideLogo.style.display = 'none';
      this.data.sideBarHiddenVal(true);
    }
    // Hide Sidebar
    if(sessionStorage.getItem('alertDetails')){
      this.item = JSON.parse(sessionStorage.getItem('alertDetails'));
      if (this.item) {
        this.saveAsCopy = JSON.parse(sessionStorage.getItem('alertDetails'));
        this.updateResolveText();
        this.getAssineeList();
        this.getHistory(this.item?.guid);
        if (this.item?.customerScyllaDb?.id) {
          this.getCustomerDataByCustomerId(this.item?.customerScyllaDb?.id);
        }
        this.bindStatus();
        if (this.item?.createdDate) {
          this.updatesSla();
        }
      }
    }
  }

  updatesSla(){
    const sla = this.item?.sla || 0;
    const slaInMilliseconds = sla * 60 * 1000;
    const currentEpochTime = moment(this.item?.createdDate).valueOf();
    const totalEpocTime = currentEpochTime + slaInMilliseconds;
    const systemDate = new Date();
    const systemEpocTime = moment(systemDate).valueOf();
    if(totalEpocTime > systemEpocTime){
      console.log('rehan', totalEpocTime, systemEpocTime)
      let remainingTime = totalEpocTime - systemEpocTime;
      remainingTime = Math.round(remainingTime/60000);
      this.item['timeRemaining'] = remainingTime;
      setInterval(() => {
        remainingTime = remainingTime - 1;
        this.item['timeRemaining'] = remainingTime;
      }, 60000);
    }
    else{
      this.item['timeRemaining'] = 0;
    }
  }

  bindStatus(){
    this.statusForm.patchValue({
      status: this.item?.alertStatus
    });
  }

  bindassignee(){
    this.assigneeForm.patchValue({
      assignee: this.item?.clientsUserAssignedToId
    });
  }

  getAssineeList() {
    this.alertService.getAssigneeList().subscribe((res: any) => {
      this.assigneList = res?.data && res?.data?.content || [];
      if (this.assigneList?.length) {
        this.assigneList.forEach((item) => {
          const firstName = item?.firstName || '';
          const middleName = item?.middleName || '';
          const lastName = item?.lastName || '';
          const name = `${firstName} ${middleName} ${lastName}`;
          item['fullName'] = name.trim().replace(/ +/g, ' ');
        })
      }
      this.bindassignee();
    })
  }

  getTheme() {
    this.data.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    })
  }

  getCustomerDataByCustomerId(customerId) {
    this.data.changeLoaderVisibility(true);
    this.alertService.getCustomerDataByCustomerId(customerId).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe(async (res) => {
      this.customerDetails = res;
      if (this.customerDetails?.crrResult) {
        const parseCrrData = JSON.parse(this.customerDetails?.crrResult);
        if(parseCrrData?.crrScore){
          const crrScore = Number(parseCrrData?.crrScore);
          this.crrScore = Math.round(crrScore);
        }
        if(parseCrrData?.amlLabel){
          this.amlLabel = parseCrrData?.amlLabel;
        }
        this.policyData = await this.getPolicyData(this.item?.customerRuleId);
        if (this.policyData?.rePolicy?.policyId && this.customerDetails?.customerPolicyRiskData?.policyOutput) {
          const variables = this.customerDetails?.customerPolicyRiskData?.policyOutput[this.policyData?.rePolicy?.policyId]?.riskFactors;
          if (variables?.length) {
            await this.manipulateData(variables?.[0]);
          }
        }
      }
    });
  }

  manipulateData(item) {
    if (item?.childs?.length) {
      this.riskBreakdownData.push(item);
      for (let child of item?.childs) {
        this.manipulateData(child);
      }
    }
  }

  async getPolicyData(ruleId) {
    this.data.changeLoaderVisibility(true);
    const res = await this.alertService.getPolicyData(ruleId).toPromise();
    this.data.changeLoaderVisibility(false);
    return res
  }

  getActionKey(item) {
    if (item?.slug == 'resolve') {
      this.statusForm.get('status').setValue('CLOSED');
      this.statusForm.get('description').setValue('status closed');
      this.updateStatus();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 6) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  getHistory(guid) {
    const payload = {
      "page": this.historyPagination?.page - 1,
      "size": this.historyPagination?.size,
      "from": 0,
      "orderBy": "",
      "order": "DESC",
      "customerAlertEsGuid": guid
    }
    this.alertService.getHistoryHistory(payload).subscribe((res: any) => {
      this.history = [];
      this.historyPagination.total = res?.totalElements || 0;
      this.historyPagination.totalPage = res?.totalPages || 0;
      let data = res?.content || [];
      for (var i = 1; data?.length > i; i++) {
        let formate1 = new DatePipe('en-Us').transform(data[i].createdDate, 'dd-MM-yyyy');
        let formate2 = new DatePipe('en-Us').transform(data[i - 1].createdDate, 'dd-MM-yyyy')
        if (formate1 == formate2) {
          data[i]['sameDate'] = 'yes'
        }
      }
      this.history = [...data];
    })
  }

  handleIncrease(){
    if(this.historyPagination['page'] < this.historyPagination['totalPage']){
      this.historyPagination['page'] += 1;
      this.getHistory(this.item?.guid);
    }
  }

  handleDecrease(){
    if(this.historyPagination['page'] > 1){
      this.historyPagination['page'] -= 1;
      this.getHistory(this.item?.guid);
    }
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit();
  }

  getAssigneeName(){
    if(this.assigneeForm?.value?.assignee){
      this.assigneeName = this.assigneList?.find((f) => f?.clientsUserID == this.assigneeForm?.value?.assignee)?.fullName;
    }
  }

  updateStatus() {
    const payload = {
      customerAlertEs: { ...this.saveAsCopy, alertStatus: this.statusForm?.value?.status },
      custAlertHistoryMsg: 'STATUS_UPDATED',
      guid: this.item?.guid
    }
    this.data.changeLoaderVisibility(true);
    this.alertService.updateAlertData(payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res: any) => {
      this.data.toastrShow(`Status has been updated from ${this.item?.alertStatus} to ${res?.alertStatus}`, 'success');
      sessionStorage.setItem('alertDetails', JSON.stringify(res));
      this.saveAsCopy = {...res};
      this.item.alertStatus = res?.alertStatus;
      this.getHistory(this.item?.guid);
      this.bindStatus();
      this.updateResolveText();
    });
  }

  updateAssignee() {
    const payload = {
      customerAlertEs: { ...this.saveAsCopy, clientsUserAssignedToId: this.assigneeForm?.value?.assignee, clientUserName: this.assigneeName },
      custAlertHistoryMsg: 'ASSIGN_CHANGED',
      guid: this.item?.guid
    }
    this.data.changeLoaderVisibility(true);
    this.alertService.updateAlertData(payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe((res: any) => {
      this.data.toastrShow(`Assignee has been updated from ${this.item?.clientUserName} to ${res?.clientUserName}`, 'success');
      sessionStorage.setItem('alertDetails', JSON.stringify(res));
      this.saveAsCopy = {...res};
      this.item.clientUserName = res?.clientUserName;
      this.item.clientsUserAssignedToId = res?.clientsUserAssignedToId;
      this.getHistory(this.item?.guid);
      this.bindassignee();
      this.assigneeName = null;
    });
  }

  openModal(TemplateRef) {
    this.modal.open(TemplateRef, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      backdrop: "static",
      modalDialogClass:
        this.theme === "theme-dark" ? "dark-modal" : "light-modal",
    });
  }

  updateResolveText() {
    if (this.item?.alertStatus == 'CLOSED') this.actionsArray = this.actionsArray.map((e) => ({ ...e, name: e?.slug == 'resolve' ? 'Resolved' : e?.name }));
  }

}
