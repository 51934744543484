import { Component,OnInit,ChangeDetectorRef,HostListener,} from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "./shared/kyc-modal/kyc-modal.component";
import { DataService } from "./services/data.service";
import { MessagingService } from "./services/messaging.service";
import { AppService } from "./services/app.service";
import { TrackingService } from "./services/tracking.service";
import { TourService } from "./services/tour.service";
import { PaymentService } from "./services/payment.service";
import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { termsAndCondition } from "./utils/terms-and-condition";
import themeData from "../assets/constants/theme-variables";



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "kyc-adminto";
  shouldShow: boolean = true;
  userName:string='';
  nonGuardedRoutes = [
    "/login",
    "/register",
    "/reset-password",
    "/setup-account",
    "/forgot-password",
    "/verify-email",
    "/404",
    "/subscribe/payment",
    "/services/register",
    "/services/payment",
    "/change-password"
  ];
  currentUrl: string;

  theming: any[] = ["theme-light", "theme-dark", "theme-golden", null];

  currentTheme: string = null;

  //Modal
  bsModalRef: BsModalRef;

  //Universal UI blocker loader
  showLoader: boolean = false;
  showAMLLoader: boolean = false;

  //firebase thing
  message: any;

  currentRoute: string;

  //Tour Vars
  showKycTour: boolean = false;
  tourData: any = null;
  clientData: any;
  termsAndCondition = termsAndCondition;
  showPageBool: boolean = true;
  themeData:any= themeData
  // color_prime:any
  @HostListener("window:resize", ["$event"])
  public onResize(event) {
    if (event.target.innerWidth < 1200) {
      this.showKycTour = false;
    }
  }


  constructor(
    private modalService: BsModalService,
    private _data: DataService,
    private _app: AppService,
    private _track: TrackingService,
    private _tour: TourService,
    public cdRef: ChangeDetectorRef,
    private _payment: PaymentService,
    public router: Router,
    private firebaseMsg: MessagingService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        this.showKycTour = false;
        this.currentUrl = e["url"];
        var element = document.getElementById("wrapper");
        element.classList.remove("enlargedTransaction");
        this.showPage(this.currentUrl);
        let token = this._data.getCookiesByName("token");
        if(token){
          this.getUserName();
          this.getThemeAttributes();
          this.firebaseMsg.requestPermission();
        }
      });
      for (let [key, value] of Object.entries(this.themeData.defaultTheme.data)) {
        let v:any = value
        document.documentElement.style.setProperty(key,v);
      }
  }

  ngOnInit() {
    this._app.showTutorial.subscribe((result) => {
      if (result == true) {
        this.watchTutorial(this.router.url);
      }
    });
    let token = this._data.getCookiesByName("token");
    if(token){
      this.getUserName();
      this.getThemeAttributes();
      this.getAlertStatus();
      this.firebaseMsg.requestPermission();
    }
    
    //Setting default theme
    localStorage.getItem("currentTheme") == null  ? (this.currentTheme = "theme-light") : (this.currentTheme = localStorage.getItem("currentTheme"));
    if(localStorage.getItem("currentTheme") == null){
      localStorage.setItem("currentTheme","theme-light")
      this._app.setCurrentTheme('theme-light')
    }

    // this._data.startTour();
    this._data.loaderInfo.subscribe((val) => {
      this.showLoader = val;
      this.cdRef.detectChanges();
    });

    this._data.loaderInfoAML.subscribe((val) => {
      this.showAMLLoader = val;
      this.cdRef.detectChanges();
    });

    
  }
  getUserName(){
    this._app.getClientUserName().subscribe((res:any)=>{
      this.userName=(res.data.firstName|| "")+" "+(res.data.middleName|| "")+" "+(res.data.lastName|| "")
    })
  }
  getThemeAttributes() {
    this._app.getThemes().subscribe((res:any)=>{
      JSON.parse(res.data.systemDefinedThemes.find(({id})=>id === res.data.selectedThemeId).themeAttributes).forEach(atr => {
        document.documentElement.style.setProperty(atr.key,atr.value);
      });
     
    },err=>{
      // in fall back case, applying default theme
      for (let [key, value] of Object.entries(this.themeData.defaultTheme.data)) {
        let v:any = value
        document.documentElement.style.setProperty(key,v);
      }
    })
  }
  getClientProfileData() {
    this._app.getClientProfileData().subscribe(res => {
      this.clientData = res.data;
      if (!this.clientData.termsAndConditionAccepted) {
        const initialState = {
          title: `Terms And Condition`,
          type: "terms-and-condition",
          termsAndCondition: termsAndCondition,
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
          initialState,
          class: "modal-lg",
          backdrop: "static",
        });
        this.bsModalRef.content.closeBtnName = "Close";
        this.bsModalRef.content.onClose.subscribe((isTermsAndCondition) => {
          if (isTermsAndCondition == "enabled") {
            this._app.acceptTermsAndCondition().subscribe((res) => {
              this.clientData.termsAndConditionAccepted = true;
              this._data.changeClientData(this.clientData);
            });
          }
        });
      } else {
        this._data.changeClientData(this.clientData);
      }

      if (
        this.clientData != null &&
        this.clientData.clientVerificationForPayment &&
        this.currentUrl.includes("verify")
      )
        this.router.navigate(["home"]);
    });
  }
  public onRouterOutletActivate(event: any) {
    if (event instanceof NotfoundComponent) {
      this.currentRoute = "404: Page Not Found";
    } else {
      this.currentRoute = this.router.url.slice(1);
      this.currentRoute = this._data.removeQueryParams(this.currentRoute);
      this.currentRoute = this.currentRoute.substring(
        0,
        this.currentRoute.indexOf("/")
      ).length
        ? this.currentRoute.substring(0, this.currentRoute.indexOf("/"))
        : this.currentRoute;
    }
  }

  getCurrentSubscription() {
    this._payment.getCurrentSubscription().subscribe((res) => {
      let accessibleStandardServices = res.data
        ? res.data.subscriptionPlan.standardServices
        : [];
      let accessibleStandardCheck = res.data
        ? res.data.subscriptionPlan.standardCheck
        : [];
      if (accessibleStandardServices && accessibleStandardServices.length) {
        this._data.changeClientStandardServices(accessibleStandardServices);
        this._data.changeClientStandardChecks(accessibleStandardCheck);
      }
    });
  }



  showPage(currentUrl) {

    let access_token = this._data.getCookiesByName("token");
    if (access_token) {
      if (currentUrl.includes("/setup-account")) {
        this.showPageBool = true;
        return true;
      }
      this.showPageBool = false;
      return false;
    }
    this.showPageBool = true;
    return true;
  }

  showTutorialFab(currentUrl) {
    // console.log(currentUrl);
    let tutPresent = ["/home", "/subscriptions"];
    let current = currentUrl.substring(0, currentUrl.indexOf("?")).length
      ? currentUrl.substring(0, currentUrl.indexOf("?"))
      : currentUrl;
    return (
      !this.nonGuardedRoutes.includes(current) && tutPresent.includes(current)
    );
  }

  watchTutorial(url) {
    if (this._tour.isTutorialPresent(url)) {
      this.showKycTour = true;
      this.tourData = this._tour.getTourData(url);
    } else {
      this._data.toastrShow(
        "Sorry , We don't have Tutorial for this section. Please look at our FAQ for more info. You can always contact us for help , We are ready to serve you!",
        "info"
      );
    }
  }
  public themeChanged(theme: string): void {
    this.currentTheme = theme;
  }
  setTheme(theme) {
    this.currentTheme = theme;
  }

  onTourUpdate(e) {
    this.showKycTour = e;
  }

  configureTheming() {
  }

  openModal() {
    const initialState = {
      title: `Create Custom Theme`,
      type: "create-custom-theme",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg modal-theming",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe((result) => {
      if (Object.keys(result).length) {
      }
    });
  }

  getAlertStatus(){
    this._app.getAlertStatus().subscribe((res) => {
      if(res){
        const jsonData = JSON.stringify(res);
        if(sessionStorage.getItem('alertStatus') != jsonData) sessionStorage.setItem('alertStatus', jsonData);
      }
    })
  }

}
