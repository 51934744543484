import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';
import { AppService } from "../../services/app.service";

@Component({
  selector: 'app-kyc-tour',
  templateUrl: './kyc-tour.component.html',
  styleUrls: ['./kyc-tour.component.scss']
})
export class KycTourComponent implements OnInit {
	
	@Input() tourData      : any ;
	@Output() updateKycTour: EventEmitter<any> = new EventEmitter();
	
	activeContentObj       : any = null ;
	
	leftOffset             : any = null ;
	topOffset              : any = null ;
	index                  : any = 0 ;
	
	elemID                 : any ;

	constructor(private app:AppService){ }

	ngOnInit(){
	  this.activeContentObj = this.tourData.tourData[this.index];
	  console.log(this.tourData);
	  this.renderTourDesign(this.activeContentObj) ;
	}

	renderTourDesign(activeContentObj){
	  this.elemID = activeContentObj.elemID ;
	  console.log("active content obj: ",this.activeContentObj);
	  var selectedElem = document.querySelector(`[data-elemID="${this.elemID}"]`) ;
	  console.log(selectedElem);
	  //scroll to that elem starts
	    selectedElem.scrollIntoView({
	      behavior: "smooth",
	      block:  "end"
	    });
	    //scroll to that elem ends
	  selectedElem.classList.add("tour-overlay") ;
	  var boundingClientRect = document.querySelector(`[data-elemID="${this.elemID}"]`).getBoundingClientRect() ;
	  this.paintTourBox(boundingClientRect , activeContentObj.orientation,activeContentObj);
	}

	paintTourBox(boundingClientRect , orientation,obj){
	  if(orientation == 'left'){
	    this.leftOffset = boundingClientRect.left + boundingClientRect.width +  30  ;
	    this.topOffset  = boundingClientRect.top - 10 + window.scrollY ;
	  }
	  else if(orientation == 'top'){
	    console.log("object: ",this.activeContentObj);
	    if(obj["position"]==undefined || (obj["position"]!=undefined && obj["position"]!='slider')){
	      this.leftOffset = boundingClientRect.left + (boundingClientRect.width/2) -  (210/2)  ; //here 222/2 Represents half of the box size
	      this.topOffset  = boundingClientRect.top + boundingClientRect.height + window.scrollY  ;
	    }
	    else{
				
	      this.leftOffset = screen.width /2;
	      this.topOffset = boundingClientRect.top + boundingClientRect.height + window.scrollY;
	    }
	    console.log("leftoffset: "+this.leftOffset+" topoffset: "+this.topOffset)
	  }
	  else if(orientation == 'bottom'){
	    this.leftOffset = boundingClientRect.left + (boundingClientRect.width/2) -  (210/2)  ; //here 222/2 Represents half of the box size
	    this.topOffset  = boundingClientRect.top - 190 - 30 + window.scrollY ; //here 190 means bubble box height
	  }
	  console.log(boundingClientRect);
	}

	skip(){
	  var selectedElem = document.querySelector(`[data-elemID="${this.elemID}"]`) ;
	  selectedElem.classList.remove("tour-overlay");
	  this.updateKycTour.emit(false);
	  this.app.toggleTutorial(false);
	}

	moveTo(traverse){
		
	  var selectedElem = document.querySelector(`[data-elemID="${this.elemID}"]`) ;
	  selectedElem.classList.remove("tour-overlay");
	  this.index = traverse == 'next' ? this.index + 1 : this.index - 1 ;
	  this.activeContentObj=this.tourData.tourData[this.index];
	  this.renderTourDesign(this.activeContentObj) ;
	}

	canTraverse(traverse){
	  if(traverse == 'next'){
	    if(this.index < this.tourData.tourData.length - 1){
	      return true;
	    }
	    else{
	      return false;
	    }
	  }
	  else if(traverse == 'previous'){
	    if(this.index && this.index > 0){
	      return true;
	    }
	    else{
	      return false;
	    }
	  }
	}

  // dummy(){
  // 	// var elemID = this.activeContentObj.elemID ;
  // 	// var selectedElem = document.querySelector('[data-elemID="1"]') ;
  // 	// selectedElem.classList.add("tour-overlay") ;
  // 	// // console.log(document.querySelector('[data-elemID="1"]'));
  // 	// var boundingClientRect = document.querySelector('[data-elemID="1"]').getBoundingClientRect() ;
  // 	// this.leftOffset = boundingClientRect.left + boundingClientRect.width +  30  ;
  // 	// this.topOffset  = boundingClientRect.top - 10  ;
  // 	// console.log(boundingClientRect);
  // 	// console.log(this.tourData);
  // }

}
