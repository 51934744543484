import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PasswordValidatorDirective } from './password.directive';
import { ConfirmPasswordDirective } from './confirm-password.directive';
import { EmailDirective } from './email.directive';
import { AddressValidatorDirective } from './address-validator.directive';


@NgModule({
  declarations: [
  	PasswordValidatorDirective,
  	ConfirmPasswordDirective,
  	EmailDirective,
  	AddressValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
  	PasswordValidatorDirective,
  	ConfirmPasswordDirective,
  	EmailDirective,
  	AddressValidatorDirective
  ]
})
export class FormValidatorModule { }
