import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppService, DataService } from '../../../../app/services';
import { elementIds } from '../constants';

@Component({
  selector: 'app-customer-details-header',
  templateUrl: './customer-details-header.component.html',
  styleUrls: ['./customer-details-header.component.scss','./../customer-detail.component.scss']
})
export class CustomerDetailsHeaderComponent implements OnInit,OnChanges {
  @Input() customerDetails;
  @Input() customerRiskDetails;
  elementIds = elementIds;
  widgetsData={
    type:'customer-details',
    data:[],
  }
  theme: any;
  slideConfigWidgetWrap = {
    slidesToShow:1,
    slidesToScroll: 1,
    infinite:false,
    responsive: [
      {
        breakpoint: 1368,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ],
    // prevArrow: '<span class="icon" style="align-items:flex-start;"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-up fa-2x" aria-hidden="true"></i></span>',
    // nextArrow: '<span class="icon" style="align-items:center"><i style="color:var(--primary-button-bg-color)" class="fa fa-angle-down fa-2x" aria-hidden="true"></i></span>',

  };
  

  allTabs = [
    {
      label:'Total Score',
      slug:'totalScore',
    },
    {
      label:'Score Breakdown',
      slug:'scoreBreakdown',
    }
  ]
  categoryList:any=[];
  alertTabs: any = [
    {
      label:'All',
      slug:'all',
    },
    {
      label:'High',
      slug:'High',
      img:'L1'
    },
    {
      label:'Medium',
      slug:'Medium',
      img:'L2'
    },
    {
      label:'Low',
      slug:'Low',
      img:'L3'
    },
  ];
  activeAlert: string;
  alerts: { ruleName: string; ruleDescription: string; ruleQuest: string; type: string; }[];
  filteredAlerts: any;
  amlData: any;
  policiesApplied: any=[];
  allPoliciesList: any[]=[];
  alertPayload = {
    "page": 0,
    "size": 10,
    "orderBy": null,
    "order": null,
    "ruleId": null,
    "customerId": null
  }
  totalAlerts: any;
  classObj = {
    "Low":'success-gradient-border',
    "High":'danger-gradient-border',
    "Medium":'warning-gradient-border'
  }
  customerPolicyDetails: any;
  policyOutput: any;
  policyDetails: any[]=[];
  riskCss={
    'Low':'text-success',
    "Medium":'text-warning',
    "High":'text-danger'
  }
  widgetStructure: any;
  alertWidget: { name: any; series: { name: any; value: any; }[]; }[];
  filteredAlertsData: any;
  customerAMLData: any[];

  constructor(private dataService: DataService,private _app: AppService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.customerDetails?.currentValue){
      this.customerDetails = changes.customerDetails.currentValue
    }
    if(changes?.customerRiskDetails?.currentValue){
      this.customerRiskDetails = changes.customerRiskDetails.currentValue
    }
    if(this.customerRiskDetails){
      this.formatIntoWidgetData()
    }
    if(this.customerDetails){
      if(this.customerDetails?.id){
        this.alertPayload.customerId = this.customerDetails?.id;
        this.getCustomerAlerts(this.alertPayload);
       
      }
      this.amlData = this.customerDetails?.riskCalculation?.amlResult ? JSON.parse(this.customerDetails?.riskCalculation?.amlResult ) : null;
      this.categoryList = this.amlData?.data?.content?.[0]?.masterData?.category;
      this.policiesApplied = this.customerDetails?.riskCalculation?.policyAppliedList || [];
      if(this.policiesApplied.length){
        const promise = this.policiesApplied.map(async (e: any) => await this.getAllPoliciesList(e));
        Promise.allSettled(promise)
        .then((res) => {
          let list=[]
          res.forEach(e => {
            if(e.status === 'fulfilled'){
              list.push(e.value);
            }
          });
          this.allPoliciesList = list;
        })
        .finally(() => {
          if(this.customerDetails?.id){
            this.getCustomerPolicyById(this.customerDetails?.id);
          }
        })
      }
    }
  }

  ngOnInit(): void {
    this.getTheme();
    this.activeAlert = this.alertTabs[0].slug;
    this.dataService.customerAMLDataInfo.subscribe(res => {
      if(res){
        console.log(res);
        this.customerAMLData = res
      }
    })
  }

  handleIncrease(){
    if(this.filteredAlerts?.pageable?.pageNumber +1 < this.filteredAlerts['totalPages']){
      this.alertPayload.page =  this.alertPayload.page +=1
      this.getCustomerAlerts(this.alertPayload);
    }
  }

  handleDecrease(){
    if(this.filteredAlerts?.pageable?.pageNumber +1 > 1){
      this.alertPayload.page =  this.alertPayload.page -=1
      this.getCustomerAlerts(this.alertPayload);
    }
  }

  formatIntoWidgetData(){
    this.customerRiskDetails.forEach(eh => eh['date']=new DatePipe('en-US').transform(eh?.createdDate, 'dd-MM-YY'))
    let arr=[];
    for(let ehObj of this.customerRiskDetails){
      for(let index of ehObj?.policyAppliedList){
        let obj = {}
        let ehVal = ehObj?.customerPolicyRiskData?.policyOutput?.[index];
        let filterData = ehVal?.output?.filter(eh => eh?.dataPoint === "Final Score")
        let value = parseInt(filterData?.[0]?.output[0]?.value)
        obj['policyName']=index;
        obj['value']=value;
        obj['date']=ehObj?.['date'];
        obj['data']=ehVal;
        arr.push(JSON.stringify(obj))
      }
    }
    let sortedData = Array.from(new Set(arr)).map(eh => JSON.parse(eh));
    let filteredPolicyNames = Array.from(new Set(sortedData.map(eh => eh.policyName)))
    let widgetData = filteredPolicyNames.map(eh => {
      return{
        name:eh,
        series:sortedData.map(ehs => {
          if(ehs?.policyName === eh){
            return {
              name:ehs.date,
              value:ehs.value
            }
          }
        })
      }
    })
    this.alertWidget = widgetData
  }

  async getCustomerPolicyById(id){
    await this._app.getPolicyByID(id).toPromise()
    .then((res:any) => {
      if(res){
        this.customerPolicyDetails = res?.data;
        if(this.customerPolicyDetails?.policyAppliedList?.length && this.customerPolicyDetails?.customerPolicyRiskData?.policyOutput){
          let arr = []
          for(let index of this.customerPolicyDetails?.policyAppliedList){
            let data = this.customerPolicyDetails?.customerPolicyRiskData?.policyOutput[index];
            let filterData = data?.output?.filter(eh => eh?.dataPoint === "Final Score");
            this.allPoliciesList.forEach(eh => {
              if(eh?.policyId === parseInt(index)){
                let dataObj={
                  "riskLevel":null,
                  "riskColor":null,
                  "reason":null
                }
                data?.output?.forEach(e => {
                  if(e.dataPoint === 'Risk Color'){
                    dataObj.riskColor = e.output[0].value;
                  }else if(e.dataPoint === 'Risk Level'){
                    dataObj.riskLevel = e.output[0].value;
                  }else if(e.dataPoint ==='Reason'){
                    dataObj.reason = e.output[0].value;
                  }
                })
                eh.additionalData = dataObj;
                eh.sourceData = data;
              }
            })
            let obj = {};
            obj['key']=index;
            obj['policyName']=this.allPoliciesList.filter(eh => eh?.policyId === parseInt(index))?.[0]?.name;
            obj['data']=data;
            obj['finalScore']=filterData?.[0];
            obj['finalScore'].output[0].value = parseInt(obj['finalScore']?.output[0]?.value || '0');
            obj['finalScore'].output[0]['status'] = obj['finalScore'].output[0].value <= 30 ? 'Low' : obj['finalScore'].output[0].value <= 60 ? 'Medium' : 'High';
            obj['activeTab']='totalScore';
            arr.push(obj);
          }
          this.policyDetails = arr
        }
      }
    })
  }

  getCustomerAlerts(payload){
    this._app.getCustomerAlerts(payload).subscribe((res:any) => {
      if(res && res?.data){
        this.filteredAlerts = res?.data;
        this.filteredAlertsData = res?.data?.content;
      }
    })
  }

  async getAllPoliciesList(id){
    const res:any = await this._app.getPolicyDetailsByID(id).toPromise();
    if(res && res.data){
      return res.data;
    }else{
      return false;
    }
  }

  legendID(i,item){
    return item.key
  }

  setActiveTab(index,slug){
    this.policyOutput[index]['activeTab'] = slug;
  }

  getTheme(){
    this.dataService.currentTheme.subscribe((res) => {
      if (res) {
        this.theme = res;
      } else {
        this.theme = localStorage.getItem("currentTheme");
      }
    });
  }

  goToTabs(){
    const headerHeight = 2; /* PUT HEADER HEIGHT HERE */
    const scrollToEl = document.getElementById(this.elementIds.POLICYBREAKDOWN);
    const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
    let scrolly=topOfElement-250;
    window.scroll({ top: scrolly, behavior: "smooth" });
    // window.scroll({ top: topOfElement, behavior: "smooth" });
    
  }

  setActiveAlert(slug){
    this.activeAlert = slug;
    this.filterAlerts(slug)
  }

  filterAlerts(type){
    if(type === 'all'){
      this.filteredAlertsData = this.filteredAlerts?.content;
    }else{
      this.filteredAlertsData = this.filteredAlerts?.content.filter(eh => eh.ruleSeverity === type);
    }
  }

  calcScore(val){
    if(val){
      let res = (parseInt(val?.maxScore)*parseInt(val?.value))/100
      return res
    }
    return false
  }

}
